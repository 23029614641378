import "./BasicInput.scss";

const BasicInput = ({
  name,
  errors,
  register,
  placeholder,
  isDisabled,
  errorclass,
}) => {
  return (
    <span className={"input_group" + " " + errorclass}>
      <input
        name={name}
        id={name}
        {...register}
        placeholder={placeholder}
        disabled={!!isDisabled}
        className="input"
      />
      <span className="error_class">
        {errors && errors[name] && errors[name] && (
          <small> {errors[name]?.message}</small>
        )}
      </span>
    </span>
  );
};

export default BasicInput;
