/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from "react";
import "./index.scss";
import CustomModal from "../CustomModal/CustomModal";
import useAxiosPrivate from "../../../axios/services";
import CustomButton from "../../Button/CustomButton/CustomButton";
import IconInput from "../../IconInput/IconInput";
import OTPInput from "react-otp-input";
import AadharIcon from "../../../assets/images/common/aadhar_icon.svg";
import { Info } from "react-feather";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { SEND_AADHAR_OTP, VERIFY_AADHAAR } from "../../../axios/apiendPoint";
import { toast } from "react-hot-toast";
import VerifiedModal from "../VerifiedModal/VerifiedModal";
import { useDispatch } from "react-redux";
import {
  fetchdashboardModalStates,
  setAadharDetails,
  setIsAadharValid,
} from "../../../Pages/Dashboard/ModalSection/modalSectionStore/store";
import Loader from "../../Loader/Loader";

const AadharModal = (props) => {
  const { isOpen, toggle } = props || {};

  const dispatch = useDispatch();
  const { axiosPost, axiosGet, isLoading } = useAxiosPrivate();

  //   states
  const [isAadharSuccess, setIsAadharSuccess] = useState(false);
  const [otp, setOtp] = useState("");
  const [aadharNumber, setAadharNumber] = useState(null);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [requestId, setRequestId] = useState(null);
  // const [aadharDetails, setAadharDetails] = useState(null);

  //   function to send aadhar otp
  const onSubmit = async (data) => {
    if (data?.aadhaarNumber) {
      const payload = {
        aadhaarNumber: data?.aadhaarNumber,
        consent: true,
        consentText:
          "I hear by declare my consent agreement for fetching my information via ZOOP API",
      };

      const response = await axiosPost(VERIFY_AADHAAR, payload);
      if (response?.success) {
        toast.success(response?.msg);
        setAadharNumber(data?.aadhaarNumber);
        setRequestId(response?.data?.request_id);
        setSubmitFlag(true);
      }
    }
    dispatch(fetchdashboardModalStates({ axiosGet }));
  };

  const handleToSubmitSubmitOtp = async (e) => {
    e.preventDefault();

    const payload = {
      documentType: "AADHAAR",
      documentInfo: {
        requestId,
        otp: otp,
        consentText:
          "I hear by declare my consent agreement for fetching my information via ZOOP API",
        consent: true,
      },
    };

    if (submitFlag) {
      const response = await axiosPost(SEND_AADHAR_OTP, payload);
      dispatch(setIsAadharValid(true));
      if (response?.success) {
        toast.success(response?.msg);
        dispatch(setAadharDetails(response?.data));
        setOtp("");
        setIsAadharSuccess(false);
        setAadharNumber(null);
        setSubmitFlag(false);
        dispatch(setIsAadharValid(false));
        // dispatch(setIsAadharModalDetailsToggle());
        toggle();
        dispatch(fetchdashboardModalStates({ axiosGet }));
      }
    } else {
      toast.error("Kindly enter otp");
    }
  };

  //function for otp input change
  const handleOtpCahnge = (e) => {
    setOtp(e);
    if (e.length === 6) setSubmitFlag(true);
  };

  const AadhaarSchema = yup.object().shape({
    aadhaarNumber: yup
      .string()
      .matches(/^\d{12}$/, "Invalid Aadhaar number")
      .required("Aadhaar number is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AadhaarSchema),
  });
  const registerAadharNumber = register("aadhaarNumber");

  return (
    <Fragment>
      {/* Enter aadhar details modal */}
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        modaltitle={isAadharSuccess ? null : "Verify Aadhar"}
        width="592px"
        modalbody={
          isLoading ? (
            <div className="loader_container">
              <Loader />
            </div>
          ) : isAadharSuccess ? (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <VerifiedModal verifiedText="Aadhar Verified Successfully!" />
              <CustomButton
                buttonName="SUBMIT"
                // handleClick={handleToOpenVerifyAadharMOdal}
              />
            </div>
          ) : (
            <div className="aadhar_container mb-2">
              {aadharNumber ? (
                <Fragment>
                  <IconInput
                    name="aadhaarNumber"
                    register={register}
                    isDisabled
                    defaultValue={aadharNumber}
                    inputMode="numeric"
                    icon={<img src={AadharIcon} alt="aadhar_icon" />}
                  />

                  <strong>Kindly enter your OTP</strong>

                  <OTPInput
                    value={otp}
                    onChange={handleOtpCahnge}
                    numInputs={6}
                    renderSeparator={false}
                    renderInput={(props) => (
                      <input {...props} inputMode="numeric" />
                    )}
                    containerStyle="otp_input_container"
                    inputStyle="otp_input_style"
                    inputType="number"
                  />

                  <CustomButton
                    disabled={otp.length !== 6}
                    buttonName="SUBMIT"
                    alignCenter
                    fullWidth
                    handleClick={handleToSubmitSubmitOtp}
                  />
                </Fragment>
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <IconInput
                    name="aadhaarNumber"
                    errors={errors}
                    // type="number"
                    register={registerAadharNumber}
                    // {...registerAadharNumber}
                    handleChange={(e) => {
                      if (e.target.value.length <= 12) {
                        const reg = /^[0-9\b]+$/;
                        if (e.target.value === "" || reg.test(e.target.value)) {
                          registerAadharNumber.onChange(e);
                        } else {
                          e.preventDefault();
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            "",
                          );
                        }
                      } else {
                        e.preventDefault();
                        e.target.value = e.target.value.slice(0, 12);
                      }
                    }}
                    inputMode="numeric"
                    placeholder="Enter your 12 digit aadhar number *"
                    icon={<img src={AadharIcon} alt="aadhar_icon" />}
                  />
                  <div className="aadhar_info_heading">
                    <Info size={15} />
                    Please enter the Aadhaar number that is linked to the
                    registered mobile number
                  </div>
                  <p className="allow_authorise mt_16">
                    By continuing, I authorise BimaGuide to fetch my Aadhaar
                    details
                  </p>
                  <CustomButton buttonName="Send OTP" alignCenter fullWidth />
                </form>
              )}
            </div>
          )
        }
      />
    </Fragment>
  );
};

export default AadharModal;
