// export const BASE_URL = "http://3.108.66.248:3000/api";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const BASE_URL2 = process.env.REACT_APP_BASE_URL2;
export const JARVIS_BASE_URL = process.env.REACT_APP_JARVIS_BASE_URL;

// Documnets upload api
export const GET_MODAL_STATES = "state/v1/partner-state";
export const UPLOAD_MARKSHEET = "onboarding/v1/upload-marksheet";

// Aadhar Api
export const VERIFY_AADHAAR = "onboarding/v1/send-aadhaar-otp";
export const SEND_AADHAR_OTP = "onboarding/v1/verify-document";
export const GET_AADHAR_DETAILS = "onboarding/v1/address-details";

export const UPDATE_AADHAR_ADDRESS = "onboarding/v1/address-details";
export const GET_PIN_CODE_CITY = "onboarding/v1/pincode-details";

export const SEND_BANK_DETAILS = "onboarding/v1/bank-account-verify";
export const UPLOAD_CANCEL_CHEQUE = "onboarding/v1/upload-cancel-cheque";

export const SEND_GST_DETAILS = "onboarding/v1/verify-document";

export const UPLOAD_NOC_DOCUMENTS = "onboarding/v1/upload-document";

// Trainig api
export const POST_READ_THEORY = "certifcation/v1/course-viewed";
export const GET_GI_TRAINING_QUESTION = "/certifcation/v1/gi-question";
export const POST_GI_TRAINING_QUESTION = "/certifcation/v1/gi-exam";
export const GET_LI_TRAINING_QUESTION = "/certifcation/v1/li-question";
export const POST_LI_TRAINING_QUESTION = "/certifcation/v1/li-exam";

// Quote Request api
export const GET_QUOTE_REQUEST_LIST = "partner/oqr/v1/list";
export const GET_INSURERS_ADD_ONS_LIST_FOR_NEW_QUOTE_REQUEST =
  "masters/v1/insurer-addons";
export const SEND_NEW_QUOTE_REQUEST_FORM = "partner/oqr/v1/create-quote";
export const SEND_FILL_QUOTE_DETAILS_FORM =
  "partner/oqr-actions/v1/fill-inspection-details";
export const GET_QUOTE_UPLOAD_DOCUMENTS_DETAILS_FORM =
  "partner/oqr-actions/v1/documents";
export const GET_SHARED_QUOTE_LIST = "partner/oqr-actions/v1/view-quote";
export const POST_ACCEPT_SINGLE_QUOTE_SHARED =
  "partner/oqr-actions/v1/accept-quote";
export const POST_DECLINE_SINGLE_QUOTE_SHARED =
  "partner/oqr-actions/v1/decline-quote";
export const POST_UPDATE_QUOTE_SHARED = "partner/oqr-actions/v1/update-quote";
export const POST_DECLINE_ALL_QUOTE_SHARED =
  "partner/oqr-actions/v1/decline-all-quote";
export const GET_PARTNER_MAIN_QUOTE_MODAL_DETAILS =
  "partner/oqr-actions/v1/partner-quote-modal";
export const POST_PARTNER_QUOTE_COMMENT =
  "partner/oqr-actions/v1/partner-comment";
export const POST_ACCEPT_PAYMENT =
  "partner/oqr-actions/v1/partner-payment-proof";

// Landing pages api ---------------> later removed if not in use
export const SEND_OTP = "auth/v1/send-otp";
export const VERIFY_OTP = "auth/v1/verify-otp";
export const VERIFY_PAN = "/onboarding/v1/verify-document";
export const SEND_REFERRAL_CODE = "/onboarding/v1/referral-code";
export const GET_BASIC_DETAILS_FIELDS = "/onboarding/v1/partner-basic-details";
export const SEND_BASIC_DETAILS = "/onboarding/v1/partner-basic-details";
export const UPLOAD_QUOTE_REQUEST_DOCUMENTS =
  "partner/oqr-actions/v1/documents";

// Mapping Request api
export const GET_MAPPING_REQUEST_LIST = "partner/oqm/v1/list";
export const CREATE_POLICY_MAPPING_REQUEST = "partner/oqm/v1/create";
export const GET_MAPPING_DOC_LIST = "partner/oqm/v1/documents";
// export const POST_REUPLOAD_MAPPING_DOC = "partner/oqm/v1/re-upload-document";

// Booking api
export const GET_BOOKING_LIST = "partner/booking-data/v1/list";
export const GET_BOOKING_MODAL_DATA = "partner/booking-data/v1/booking-modal";

//dashboard api
export const GET_DASHBOARD_DATA = "partner/dashboard/v1/dp";

// kyc api
export const POST_KYC_DETAILS = "partner/oqr-actions/v1/upload-kyc-document";

//Grid api
export const GET_OPTIONS_DATA = "jarvis/grid/v1/search-options";
export const GET_FAVORITE_POLICY_DEALS = "jarvis/grid/v1/favorite";
export const GET_POLICY_DEALS = "jarvis/grid/v1/partner-deals";
export const MARK_FAVORITE_POLICY_DEAL = "jarvis/grid/v1/mark-favorite";
export const UNMARK_FAVORITE_POLICY_DEAL = "jarvis/grid/v1/unmark-favorite";

export const GRID_GLOSSARY = "jarvis/grid/v1/glossary";
export const GET_PROFILING_INFO = "profile/v1/partner";
