import React, { useEffect, useState } from "react";
import "./index.scss";
import UserDropdown from "./UserDropdown";
import Notification from "./Notification";
import Information from "./Information";
import { useLocation } from "react-router-dom";
import { getCurrentPageName } from "../../../Constants/Constants";
import { useSelector } from "react-redux";
import { Heart } from "react-feather/dist";
import LogoFull from "../../../assets/logo/logo_full.svg";
import Icon from "../../../assets/images/navbar/Transparent.svg";
import CustomButton from "../../Button/CustomButton/CustomButton";
import RmInfo from "./RmInfo";

const Navbar = () => {
  const location = useLocation();
  const url = getCurrentPageName(location.pathname);

  const { modalStateDetails, rmData } = useSelector(
    (state) => state.dashboardModalReducer,
  );

  const { name = null, userId = null } = modalStateDetails?.data || {};

  // const { pagesWithSearchBox } = useSelector((state) => state.searchBoxReducer);

  // const [showSearchBox, setShowSearchBox] = useState(
  //   pagesWithSearchBox.includes(location.pathname),
  // );

  const [showGoToFavorites, setShowGoToFavorites] = useState("");

  const handleGoToFavorites = () => {
    // if grid/faorites page is already open then redirect to grid page else redirect to favorites page
    if (location.pathname.includes("favorites")) {
      window.location.href = "/grid";
    } else {
      window.location.href = "grid/favorites";
    }
  };

  useEffect(() => {
    // setShowSearchBox(pagesWithSearchBox.includes(location.pathname));

    if (location.pathname.includes("grid/favorites")) {
      setShowGoToFavorites("Hide Favorites");
    } else if (location.pathname.includes("grid")) {
      setShowGoToFavorites("Go to Favorites");
    } else {
      setShowGoToFavorites("");
    }
  }, [location.pathname]);

  return (
    <div className="navbar_container ">
      <div
        className=""
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="toggle-set">
          {" "}
          <img src={Icon} alt="logo" />
        </span>
        <span className="bima-image">
          {" "}
          <img
            src={LogoFull}
            alt="logo"
            style={{ width: "107px", height: "24px" }}
          />
        </span>
        <div className="navbar_component_name">{url}</div>
      </div>

      {/* Searchbox */}
      {/* {showSearchBox && <SearchBox />} */}
      <div className="d-flex align-items-center justify-content-between py-0 my-0 gap-2">
        {/* Conditionally render Information component only on 'grid' page */}
        {location.pathname.includes("grid") && <Information />} <Notification />
        {showGoToFavorites && (
          <CustomButton
            className={
              showGoToFavorites === "Hide Favorites"
                ? "secondary_button"
                : "secondary_button outlined"
            }
            buttonName={showGoToFavorites}
            icon={<Heart />}
            handleClick={() => handleGoToFavorites()}
          />
        )}
        <div>
          {rmData ? (<RmInfo rmData={rmData} />) : ("")}
        </div>
        <UserDropdown name={name} userId={userId} />
      </div>
    </div>
  );
};

export default Navbar;
