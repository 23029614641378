import React, { useState, useEffect } from "react";
import CustomModal from "../../../Components/Modal/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQuoteRequestData,
  fetchSharedQuoteData,
  toggleViewQuoteModal,
} from "../store/store";
import CustomButton from "../../../Components/Button/CustomButton/CustomButton";
// import Icici from "../../../assets/images/Removed/icici.svg";
import { Check, Edit2, Eye, X } from "react-feather";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import FormInput from "../../../Components/FormInput/FormInput";
import useAxiosPrivate from "../../../axios/services";
import { toast } from "react-hot-toast";
import {
  POST_ACCEPT_SINGLE_QUOTE_SHARED,
  POST_DECLINE_ALL_QUOTE_SHARED,
  POST_DECLINE_SINGLE_QUOTE_SHARED,
  POST_UPDATE_QUOTE_SHARED,
} from "../../../axios/apiendPoint";
import {
  filteredData,
  declineReasonOptions,
  QuoteStatusList,
} from "../../../Constants/Constants";
import DeclineAllModal from "../../../Components/Modal/DeclineAllModal/DeclineAllModal";
import QuoteDynamicHeader from "../../../Components/QuoteDynamicHeader/QuoteDynamicHeader";
import LookingLottie from "../../../assets/lottie/looking.json";
import MultiSelect from "../../../Components/MultiSelect/MultiSelect";
import { InsurerLogoMap } from "../../../Constants/InsurerLogoMap";

const ViewQuoteModal = () => {
  const dispatch = useDispatch();
  const { axiosGet2, axiosPost2 } = useAxiosPrivate();

  const isOpen = useSelector(
    (state) => state.quoteRequestReducer.showViewQuoteModal,
  );
  const currentQuote = useSelector(
    (state) => state.quoteRequestReducer.currentQuoteData,
  );
  const docList = useSelector(
    (state) => state.quoteRequestReducer.sharedQuoteList,
  );
  const [declineAllFlag, setDeclineAllFlag] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [declineReasonError, setDeclineReasonError] = useState("");
  const { oqrData = [] } = docList || {};
  const [isDeclinedSuccess, setIsDeclinedSuccess] = useState(false);

  const openDeclineAllModal = () => {
    setDeclineAllFlag(true);
  };

  const [openFormIndices, setOpenFormIndices] = useState([]);
  const [acceptQuoteFlag, setAcceptQuoteFlag] = useState(false);
  const [declineQuoteFlag, setDeclineQuoteFlag] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);

  const toggle = () => dispatch(toggleViewQuoteModal());

  // Function for accept / decline
  const handleToClickQuoteDetails = (index, name) => {
    setOpenFormIndices([index]); // Set only the clicked form to be open
    setAcceptQuoteFlag(name === "changes");
    setDeclineQuoteFlag(name === "decline");
    let itemData = oqrData[index];
    setValue("addOns", itemData.addOns || []);
  };

  const changeQuoteSchema = yup.object().shape({
    idv: yup.string().required("IDV is required"),
    ncb: yup
      .number("NCB is Required")
      .typeError("NCB is Required")
      .required("NCB is required")
      .min(0, "NCB can't be less than 0")
      .max(100, "NCB can't be more than 100"),
    // addOns: yup.string().required("Add On is required"),
    discount: yup
      .number("Discount is Required")
      .typeError("Discount is Required")
      .required("Discount is required")
      .min(0, "Discount can't be less than 0")
      .max(100, "Discount can't be more than 100"),
  });

  const declineQuoteSchema = yup.object().shape({
    declineReason: yup.string().required("Decline reason is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(
      acceptQuoteFlag ? changeQuoteSchema : declineQuoteSchema,
    ),
  });

  // Handle to reset
  const handleReset = () => {
    reset();
    setOpenFormIndices([]);
    setAcceptQuoteFlag(false);
    setDeclineQuoteFlag(false);
    setSelectedQuote(null);
  };

  // Handle to submit Accept one quote
  const handleToSubmitAcceptSingleQuote = async (data) => {
    const payload = { quoteRequestId: data?.quoteRequestId };
    const response = await axiosPost2(POST_ACCEPT_SINGLE_QUOTE_SHARED, payload);
    if (response?.success) {
      toast.success(response?.msg);
      handleReset();
      toggle();
      dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));
    }
  };

  // Handle to submit decline one quote
  const handleToSubmitDeclineSingleQuote = async (data) => {
    const payload = { quoteRequestId: selectedQuote?.quoteRequestId, ...data };
    const response = await axiosPost2(
      POST_DECLINE_SINGLE_QUOTE_SHARED,
      payload,
    );
    if (response?.success) {
      toast.success(response?.msg);
      handleReset();
      toggle();
      dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));
    }
  };

  // Handle to submit update quote
  const handleToSubmitUpdateQuote = async (data) => {
    // to be removed later ------------------
    const falseData = {};
    falseData.declineReason = data.declineReason;
    falseData.idv = data.idv;
    falseData.ncb = data.ncb;
    falseData.discount = data.discount;
    if (data.addOns) falseData.addOns = [data.addOns];
    if (data.remarks) falseData.remarks = data.remarks;
    // to be removed later ------------------

    const payload = {
      quoteRequestId: selectedQuote?.quoteRequestId,
      ...falseData,
    };
    const response = await axiosPost2(POST_UPDATE_QUOTE_SHARED, payload);
    if (response?.success) {
      toast.success(response?.msg);
      handleReset();
      toggle();
      dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));
    }
  };

  // Handle To decline all quotes
  const handleToDeclineAllQuotes = async () => {
    if (!declineReason || declineReason === "") {
      setDeclineReasonError("Decline reason is required");
      return;
    } else {
      setDeclineReasonError("");
    }
    const payload = {
      quoteId: currentQuote?.quoteId,
      declineReason: declineReason,
    };
    const response = await axiosPost2(POST_DECLINE_ALL_QUOTE_SHARED, payload);
    if (response?.success) {
      toast.success(response?.msg);
      handleReset();
      setIsDeclinedSuccess(true);
      dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));
    }
  };

  const handleViewAllQuotesButton = () => {
    let downloadLinks = oqrData?.map((item) => {
      return item?.quoteUrl;
    });

    downloadLinks = downloadLinks.filter((item) => {
      return item !== undefined && item !== null && item !== "";
    });

    let downloadHrefsbutton = document.createElement("a");

    for (let i = 0; i < downloadLinks.length; i++) {
      downloadHrefsbutton.href = downloadLinks[i];
      downloadHrefsbutton.target = "_blank";
      downloadHrefsbutton.click();
    }
  };

  let isQuoteChangeRequested = false;
  const onSubmit = (data) => {
    // Handle form submission

    const finalData = filteredData(data);
    if (declineQuoteFlag && selectedQuote !== null)
      handleToSubmitDeclineSingleQuote(finalData);

    if (acceptQuoteFlag && selectedQuote !== null)
      handleToSubmitUpdateQuote(finalData);
  };

  //Fetch quote request list
  const getSharedQuoteDetails = (params) =>
    dispatch(fetchSharedQuoteData({ axiosGet: axiosGet2, params }));

  useEffect(() => {
    if (isOpen) {
      const params = `quoteId=${currentQuote?.quoteId}`;
      getSharedQuoteDetails(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuote?.quoteId, isOpen]);

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      width="733px"
      modaltitle="Shared Quotes"
      modalbody={
        <div className="quote_request_shared_quote_container">
          <div className="quote_request_shared_all_quote_action">
            <span
              className="cursor_pointer text-end view_all_quotes"
              onClick={handleViewAllQuotesButton}
            >
              View All Quotes
            </span>
            <span
              className="cursor_pointer text-end decline_text"
              onClick={openDeclineAllModal}
            >
              Decline All
            </span>
          </div>
          <div className="d-flex flex-column gap-3">
            {oqrData?.map((item, index) => {
              const isFormOpen = openFormIndices.includes(index);

              return (
                <div className="quote_card_container" key={index}>
                  <div
                    key={index}
                    className={`quote_card 
              quote_card_for_modal 
              ${item.quoteStatus === QuoteStatusList.ACCEPTED || item.quoteStatus === QuoteStatusList.ACCEPTED_WITH_INSPECTION ? "quote_accepted_card" : ""}
              ${item.quoteStatus === QuoteStatusList.DECLINED ? "quote_declined_card" : ""}
              ${item.quoteStatus === QuoteStatusList.UNAVAILABLE ? "quote_unavailable_card" : ""}
              ${item.quoteStatus === QuoteStatusList.CHANGE_REQUESTED ? "quote_change_requested_card" : ""}
              ${isFormOpen && acceptQuoteFlag ? "is_change_open" : ""}
              ${isFormOpen && declineQuoteFlag ? "quote_declined_card" : ""}   
              `}
                  >
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      {item.insurerId && InsurerLogoMap[item.insurerId] ? (
                        <img
                          src={InsurerLogoMap[item.insurerId]?.Logo}
                          alt={index}
                          className="logo_image"
                        />
                      ) : (
                        <div className="logo_name">{item.insurerName}</div>
                      )}

                      <div className="quote_status_btn modal_actions">
                        {[
                          QuoteStatusList.ACCEPTED,
                          QuoteStatusList.SHARED,
                          QuoteStatusList.ACCEPTED_WITH_INSPECTION,
                        ].includes(item.quoteStatus) && (
                          <>
                            <div className="view_quote_btn modal_action_button">
                              <a
                                href={item?.quoteUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Eye size={16} />
                                &nbsp; View Quote
                              </a>
                            </div>
                            {!isFormOpen &&
                              !item.inspectionRequired &&
                              ![
                                QuoteStatusList.ACCEPTED,
                                QuoteStatusList.ACCEPTED_WITH_INSPECTION,
                              ].includes(item.quoteStatus) && (
                                <button
                                  className="option_btn accept_btn modal_action_button"
                                  onClick={() =>
                                    handleToSubmitAcceptSingleQuote(item)
                                  }
                                  // disabled={isChangeRequestInProcess}
                                >
                                  <Check size={16} /> Accept
                                </button>
                              )}
                            {!isFormOpen &&
                              item.inspectionRequired &&
                              ![
                                QuoteStatusList.ACCEPTED,
                                QuoteStatusList.ACCEPTED_WITH_INSPECTION,
                              ].includes(item.quoteStatus) && (
                                <button
                                  className="option_btn accept_btn modal_action_button"
                                  onClick={() =>
                                    handleToSubmitAcceptSingleQuote(item)
                                  }
                                  // disabled={isChangeRequestInProcess}
                                >
                                  <Check size={16} /> Accept with inspection
                                </button>
                              )}
                            {!isQuoteChangeRequested && (
                              <button
                                className="option_btn changes_btn"
                                onClick={() => {
                                  handleToClickQuoteDetails(index, "changes");
                                  setSelectedQuote(item);
                                }}
                              >
                                <Edit2 size={16} /> Changes
                              </button>
                            )}
                            <button
                              className="option_btn changes_btn"
                              onClick={() => {
                                handleToClickQuoteDetails(index, "decline");
                                setSelectedQuote(item);
                              }}
                            >
                              <X size={16} /> Decline
                            </button>
                          </>
                        )}
                        {item.quoteStatus === QuoteStatusList.UNAVAILABLE && (
                          <div className="quote_not_found_btn">
                            {item.quoteStatusName}
                          </div>
                        )}
                        {item.quoteStatus === QuoteStatusList.DECLINED && (
                          <div className="quote_not_found_btn">
                            {item.quoteStatusName}
                          </div>
                        )}
                        {item.quoteStatus ===
                          QuoteStatusList.CHANGE_REQUESTED && (
                          <div className="quote_change_requested_btn">
                            {item.quoteStatusName}
                          </div>
                        )}
                      </div>
                    </div>

                    {item.quoteStatus === QuoteStatusList.CHANGE_REQUESTED && (
                      <QuoteDynamicHeader
                        title="Our Team is working on it"
                        subTitle="Our Team will update you shortly on the status"
                        noCard
                        customImage
                        lottieImage={LookingLottie}
                      />
                    )}

                    {isFormOpen && (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {acceptQuoteFlag && openFormIndices.includes(index) && (
                          <div className="quote_form row">
                            <p className="heading">Request Changes</p>
                            <div className="my-3 col-6">
                              <Controller
                                control={control}
                                name={`idv`}
                                render={({ field }) => (
                                  <FormInput
                                    inputMode={"currency"}
                                    field={field}
                                    placeholder="Enter IDV"
                                    maxLength={10}
                                  />
                                )}
                              />
                              {errors.idv && (
                                <div className="error_class">
                                  {errors.idv.message}
                                </div>
                              )}
                            </div>

                            <div className="my-3 col-6">
                              <Controller
                                control={control}
                                name={`ncb`}
                                render={({ field }) => (
                                  <FormInput
                                    field={field}
                                    placeholder="Enter NCB %"
                                  />
                                )}
                              />
                              {errors.ncb && (
                                <div className="error_class">
                                  {errors.ncb.message}
                                </div>
                              )}
                            </div>

                            <div className="my-3 col-6">
                              <Controller
                                control={control}
                                name={`addOns`}
                                render={({ field }) => {
                                  return (
                                    <MultiSelect
                                      {...field}
                                      onChange={(selected) => {
                                        field.onChange(
                                          selected
                                            ? selected.map(
                                                (option) => option.value,
                                              )
                                            : [],
                                        );
                                      }}
                                      placeholder="Select Add Ons"
                                      isSearchable
                                      isMulti
                                      options={
                                        item.totalAddOns &&
                                        item.totalAddOns.map((item) => ({
                                          label: item,
                                          value: item,
                                        }))
                                      }
                                      className="react_select"
                                      value={
                                        field.value &&
                                        field.value.map((item) => ({
                                          label: item,
                                          value: item,
                                        }))
                                      }
                                    />
                                  );
                                }}
                              />
                              {errors.addOns && (
                                <div className="error_class">
                                  {errors.addOns.message}
                                </div>
                              )}
                            </div>
                            <div className="my-3 col-6">
                              <Controller
                                control={control}
                                name={`discount`}
                                render={({ field }) => (
                                  <FormInput
                                    inputMode="decimal"
                                    field={field}
                                    placeholder="Enter Discount"
                                  />
                                )}
                              />
                              {errors.discount && (
                                <div className="error_class">
                                  {errors.discount.message}
                                </div>
                              )}
                            </div>

                            <div className="d-flex align-items-center justify-content-between m3-3 mb-5">
                              <CustomButton
                                buttonName="CANCEL"
                                handleClick={handleReset}
                                alignCenter
                                className="cancel_btn"
                                btnNameClass="cancel_btn_name"
                              />
                              <CustomButton type="submit" alignCenter />
                            </div>
                          </div>
                        )}

                        {declineQuoteFlag &&
                          openFormIndices.includes(index) && (
                            <div className="quote_form">
                              <p className="heading">Decline Quote</p>
                              <div className="my-3">
                                <Controller
                                  control={control}
                                  name={`declineReason`}
                                  render={({ field }) => (
                                    <FormInput
                                      field={field}
                                      placeholder="Select Decline Reason"
                                    />
                                  )}
                                />
                                {errors.declineReason && (
                                  <div className="error_class">
                                    {errors.declineReason.message}
                                  </div>
                                )}
                              </div>

                              <div className="my-3">
                                <Controller
                                  control={control}
                                  name={`remarks`}
                                  render={({ field }) => (
                                    <FormInput
                                      field={field}
                                      placeholder="Enter Remarks (if any)"
                                    />
                                  )}
                                />
                              </div>

                              <div className="d-flex align-items-center justify-content-between m3-3 mb-5">
                                <CustomButton
                                  buttonName="CANCEL"
                                  handleClick={handleReset}
                                  alignCenter
                                  className="cancel_btn"
                                  btnNameClass="cancel_btn_name"
                                />
                                <CustomButton type="submit" alignCenter />
                              </div>
                            </div>
                          )}
                      </form>
                    )}
                  </div>

                  {/* addons list */}
                  {item.addOns && item.addOns.length > 0 && (
                    <div className="quote_addons_list">
                      {/* string concat */}
                      <strong>Addons:</strong>
                      &nbsp;
                      {item.addOns.join(", ")}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="d-flex align-items-center justify-content-end my-4">
            <CustomButton
              handleClick={() => {
                toggle();
                handleReset();
              }}
              alignCenter
            />
          </div>

          {/* Decline All Modal */}
          {declineAllFlag && (
            <DeclineAllModal
              declineAllFlag={declineAllFlag}
              setDeclineAllFlag={setDeclineAllFlag}
              declineReasonOptions={declineReasonOptions}
              declineReason={declineReason}
              setDeclineReason={setDeclineReason}
              declineReasonError={declineReasonError}
              handleToDeclineAllQuotes={handleToDeclineAllQuotes}
              isDeclinedSuccess={isDeclinedSuccess}
            />
          )}
        </div>
      }
    />
  );
};

export default ViewQuoteModal;
