import toast from "react-hot-toast";
import CertificatePage from "./Certificate";
import { createRoot } from "react-dom/client";

export const handleDownloadCertificate = (profileData) => {
  const newTab = window.open("about:blank", "_blank");

  if (newTab) {
    // Create a container for rendering React components
    const container = document.createElement("div");
    newTab.document.body.appendChild(container);
    // Add necessary styles and title
    const styleElement = newTab.document.createElement("style");
    styleElement.innerHTML = `
      /* General styles */
      .printable-page {
        font-family: Arial, sans-serif;
        }

        .watermark {
        position: fixed;
        top: 25%;
        right: 30%;
        width: 15cm;
        height: 15cm;
        z-index: -5000;
        opacity: 0.3; 
      }

      .header {
        position: fixed;
        top: 0;
        width: 100%;
        text-align: center;
        padding: 10px 0;
      }

      .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding: 10px 0;
        font-size: 12px;
        }
        
      /* Ensure content doesn't overlap header and footer */
      .page-content {
        margin: 80px 20px 50px 20px; /* Adjust margin to fit header/footer */
      }

      /* Handle page breaks for printing */
      @media print {
        .page-break {
          page-break-before: always; /* Force a new page before this element */
        }

        .header,
        .footer {
          position: fixed; /* Keep header and footer in place on all pages */
          }
          
          .page-content {
          margin-top: 100px; /* Push content below the header */
          margin-bottom: 60px; /* Push content above the footer */
          }

          /* Remove unnecessary elements for print */
          button,
          .no-print {
            display: none;
            }
      }
      
      `;
    newTab.document.head.appendChild(styleElement);

    newTab.document.title = "Certificate";

    // Use createRoot to render the component
    const root = createRoot(container);
    root.render(
      <CertificatePage
        onRendered={() => {
          setTimeout(() => {
            newTab.print();
          }, 100);
        }}
        certificateDetails={profileData}
      />,
    );

    newTab.document.body.onload = () => {
    };
  } else {
    toast.error("Failed to open a new tab. Please check popup blockers.");
  }
};
