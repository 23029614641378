import React, { useCallback, useEffect, useState } from "react";
import QuoteTable from "./QuoteTable";
import { useSelector, useDispatch } from "react-redux";
import { fetchQuoteRequestData } from "./store/store";
import useAxiosPrivate from "../../axios/services";
import "./index.scss";
import { debounce, formatDate } from "../../utils/utils";
import "flatpickr/dist/flatpickr.css";
import { Search } from "react-feather";
import FormInput from "../../Components/FormInput/FormInput";
import DatePicker from "../../Components/DatePicker/Datepicker";
import DropDown from "../../Components/DropDown/DropDown";
import CustomTabing from "../../Components/CustomTabing/CustomTabing";
import { CustomPagination } from "../../Components/Pagination/Pagination";
import { Product } from "../../Constants/Constants";
import Loader from "../../Components/Loader/Loader";

const QuoteRequest = () => {
  const { axiosGet2 } = useAxiosPrivate();
  const dispatch = useDispatch();
  const { isLoading, quoteBucket, pagination, quoteList } = useSelector(
    (state) => state.quoteRequestReducer,
  );
  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [currentBucket, setcurrentBucket] = useState();
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [product, setProduct] = useState("");
  const [search, setSearch] = useState("");
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });

  // Debounce the search input
  const debounceSearch = useCallback(
    debounce((searchValue) => {
      if (searchValue.length >= 3) {
        setDebouncedSearch(searchValue);
      } else {
        setDebouncedSearch("");
      }
    }, 300),
    [],
  );

  //handle Search
  const handleSearch = (evt) => {
    const searchValue = evt.target.value;
    const validInput = searchValue.replace(/[^a-zA-Z0-9-\s]/g, "").slice(0, 15);

    setSearch(validInput);
    debounceSearch(validInput);
  };
  const handleDateChange = (date) => {
    if (date && date.length == 2) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };

  const handleTabChange = (newValue) => {
    if (!isLoading) {
      setcurrentBucket(newValue);
      setCurrentPage(1);
    }
  };

  const handleProductSelect = (selectedOption) => {
    setProduct(
      selectedOption?.value === "No Product" ? "" : selectedOption?.value,
    ); // Set product or empty string
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Trigger API call to fetch the booking list for the selected bucket
  useEffect(() => {
    let searchParams = {};

    if (currentBucket) {
      searchParams["curBucket"] = currentBucket;
    }

    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    if (debouncedSearch) {
      searchParams["searchQuery"] = debouncedSearch;
    }
    if (product) {
      searchParams["product"] = product;
    }

    if (filterByDate.startDate && filterByDate.endDate) {
      searchParams["startDate"] = formatDate(filterByDate.startDate);
      searchParams["endDate"] = formatDate(filterByDate.endDate);
    }

    searchParams = Object.entries(searchParams).map(
      ([key, value]) => `${key}=${value.toString()}`,
    );
    searchParams = searchParams.join("&");
    dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2, searchParams }));
  }, [currentBucket, currentPage, filterByDate, debouncedSearch, product]);

  return (
    <div className="">
      <div className="filters_container">
        <div style={{ width: "400px" }}>
          <FormInput
            icon={<Search size={24} color="#0691fc" strokeWidth={1.5} />}
            value={search}
            onChange={handleSearch}
            placeholder="Type to Search"
            style={{ height: "40px" }}
          />
        </div>
        <div
          className=""
          style={{ display: "flex", gap: "20px", alignItems: "center" }}
        >
          <label htmlFor="partner-dropdown" className="filter-label">
            Filters :
          </label>
          <div>
            <DropDown
              options={Product}
              isClearable={true}
              placeholder="Select Product"
              onSelect={handleProductSelect}
            />
          </div>
          <div>
            <DatePicker
              placeholder="Date Range"
              options={{ mode: "range" }}
              value={
                filterByDate.startDate && filterByDate.endDate
                  ? [filterByDate.startDate, filterByDate.endDate]
                  : ""
              }
              onChange={handleDateChange}
            />
          </div>
        </div>
      </div>
      {/* Header Section */}
      {isLoading ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh", // Full height of the viewport
            }}
          >
            <Loader />
          </div>
        </>
      ) : (
        <>
          <div className="custom-tabing-wrapper">
            <CustomTabing
              value={quoteBucket?.curBucket}
              handleChange={handleTabChange}
              tabList={quoteBucket?.bucketList || []}
            />
          </div>

          <div></div>
        </>
      )}
      <QuoteTable data={quoteList} isLoading={isLoading} />
      <CustomPagination
        currentPage={currentPage}
        totalPage={pagination?.totalPage || 1}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default QuoteRequest;
