/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useCallback } from "react";
import "./index.scss";
import Verified from "../../../assets/lottie/verified2.json";
import CustomButton from "../../../Components/Button/CustomButton/CustomButton";
import { ArrowRight, Info } from "react-feather";
import ReadTheoryModal from "./TrainingModal/ReadTheoryModal";
import { toggleReadTheoryModal } from "./TrainingModal/trainingModalStore/store";
import { useDispatch, useSelector } from "react-redux";
import TrainingRuleModal from "./TrainingModal/trainingModalStore/TrainingRuleModal";
import QuestionModal from "./TrainingModal/QuestionModal";
import ReverseTimer from "../../../Components/ReverseTimer/ReverseTimer";
import TimerTv from "../../../assets/images/training/timer.svg";
import { Progress } from "reactstrap";
import LottiePlayer from "../../../Components/LottiePlayer/LottiePlayer";

const StartTraining = () => {
  const dispatch = useDispatch();

  const modalDetails = useSelector(
    (state) => state.dashboardModalReducer.modalStateDetails,
  )?.data;
  const { page, remainingTime } = modalDetails || {};

  const handleClick = () => {
    // dispatch(toggleQuestionModal());
    dispatch(toggleReadTheoryModal());
  };

  const renderVerificationCompleteCard = useCallback(() => {
    return (
      <Fragment>
        {(page === "COURSE_PAGE" ||
          page === "GI_EXAM_PENDING_PAGE" ||
          page === "LI_EXAM_PENDING_PAGE") && (
          <div className="start_training_container d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-3">
              <LottiePlayer height={62} width={62} animationData={Verified} />
              <p className="m-0 p-0 verification_complete_text">
                {page === "GI_EXAM_PENDING_PAGE"
                  ? "Your verification is completed. Please complete your GI Exam"
                  : page === "LI_EXAM_PENDING_PAGE"
                    ? "Your verification is completed. Please complete your LI Exam"
                    : "Your verification is completed. Please start your training to become a certified partner"}
              </p>
            </div>

            <CustomButton
              buttonName={
                <>
                  {page === "GI_EXAM_PENDING_PAGE"
                    ? "Start GI Exam"
                    : page === "LI_EXAM_PENDING_PAGE"
                      ? "Start LI Exam"
                      : "Start Training"}
                  <ArrowRight size={24} />
                </>
              }
              alignCenter
              handleClick={handleClick}
            />
          </div>
        )}
      </Fragment>
    );
  }, [handleClick]);

  const renderTrainigTimeCard = useCallback(() => {
    return (
      <Fragment>
        {page === "TRAINING_STARTED_PAGE" && (
          <div className="training_time_container d-flex align-items-start justify-content-between">
            <img src={TimerTv} alt="timer" />
            <div className="w-100">
              <div className="d-flex align-items-center justify-content-between">
                <p className="m-0 p-0 training_heading">
                  Certification Training <Info size={14} />
                </p>

                <p
                  className="view_label cursor_pointer view_details_btn"
                  onClick={handleClick}
                >
                  View Details
                </p>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <p className="timer_badge mb-0 me-2">
                  <ReverseTimer initialSeconds={remainingTime} />
                </p>
                <Progress
                  className="w-100"
                  style={{ height: "8px" }}
                  value={remainingTime}
                  max={144000}
                />
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }, [page, remainingTime]);

  useEffect(() => {
    renderTrainigTimeCard();
  }, [page, renderTrainigTimeCard]);

  useEffect(() => {
    renderVerificationCompleteCard();
  }, [page, renderVerificationCompleteCard]);

  return (
    <Fragment>
      {renderTrainigTimeCard()}

      {renderVerificationCompleteCard()}

      {/* Read Theory Modal */}
      <ReadTheoryModal />

      {/* Traing Rule Modal */}
      <TrainingRuleModal />

      {/* Training Question Modal */}
      <QuestionModal />
    </Fragment>
  );
};

export default StartTraining;
