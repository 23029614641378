/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from "react";
import CustomModal from "../../../../Components/Modal/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTrainingModalQuestion,
  toggleQuestionModal,
  toggleRegulationModal,
} from "./trainingModalStore/store";
import CustomButton from "../../../../Components/Button/CustomButton/CustomButton";
import { ArrowRight } from "react-feather";
import useAxiosPrivate from "../../../../axios/services";
import {
  GET_GI_TRAINING_QUESTION,
  GET_LI_TRAINING_QUESTION,
  POST_GI_TRAINING_QUESTION,
  POST_LI_TRAINING_QUESTION,
} from "../../../../axios/apiendPoint";
import { useEffect } from "react";
import { Progress } from "reactstrap";
import { toast } from "react-hot-toast";
import ThumbsUp from "../../../../assets/lottie/thumbsUp.json";
import SadFace from "../../../../assets/lottie/sadFace.json";
import CircularProgressBar from "../../../../Components/CircularProgressBar/CircularProgressBar";
import { fetchdashboardModalStates } from "../../ModalSection/modalSectionStore/store";
import LottiePlayer from "../../../../Components/LottiePlayer/LottiePlayer";

const QuestionModal = () => {
  const dispatch = useDispatch();
  const { axiosGet, axiosPost } = useAxiosPrivate();

  const trainingModalDetails = useSelector(
    (state) => state.trainingModalReducer,
  );
  const { isQuestionModalOpen, trainingQuestionArray = [] } =
    trainingModalDetails || {};

  const modalDetails = useSelector(
    (state) => state.dashboardModalReducer.modalStateDetails,
  )?.data;
  const { page = null, name = null } = modalDetails || {};

  //   states
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answerList, setAnswerList] = useState([]);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [remainingTime, setRemainingTime] = useState(45 * 60 + 2); // 45 minutes in seconds

  const [testStatus, setTestStatus] = useState(null);
  const [currentExam, setCurrentExam] = useState(null); // to be come from api later
  const [percentage, setPercentage] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);

  //   Function starts from here -------------------------------------------------
  //   Function to toggle question modal
  const toggle = () => {
    setCurrentQuestionIndex(0);
    setAnswerList([]);
    setSubmitFlag(false);
    setRemainingTime(45 * 60 + 2);
    setTestStatus(null);
    setCurrentExam(null);
    setPercentage(0);
    setSelectedOption(null);
    dispatch(toggleQuestionModal());
    dispatch(fetchdashboardModalStates({ axiosGet }));
  };

  //   Function to go to rules and regulation modal
  const handleToGoBack = () => {
    toggle();
    dispatch(toggleRegulationModal());
    setAnswerList([]);
  };

  //   function to go to next question
  const handleToNextQuestion = () => {
    if (selectedOption) {
      if (
        currentQuestionIndex > -1 &&
        currentQuestionIndex < trainingQuestionArray.length - 1
      ) {
        setCurrentQuestionIndex((prev) => prev + 1);
      } else if (currentQuestionIndex === trainingQuestionArray.length - 1) {
        setCurrentQuestionIndex(trainingQuestionArray.length - 1);
      }

      setSelectedOption(null);
    } else toast.error("Kindly select an option");
  };

  //   function to go to previous question
  const handleToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
      // find
      let quesId = trainingQuestionArray[currentQuestionIndex - 1]?.questionId;
      const existingIndex = answerList.findIndex(
        (entry) => entry.quesId === quesId,
      );

      if (existingIndex !== -1) {
        let answer = answerList[existingIndex].optionId;
        setSelectedOption(answer);
      } else {
        setSelectedOption(null);
      }
    } else {
      setSelectedOption(null);
    }
  };

  const handleToSaveAnswer = (quesId, optionId) => {
    const existingIndex = answerList.findIndex(
      (entry) => entry.quesId === quesId,
    );

    if (existingIndex !== -1) {
      // Replace the existing entry with a new object
      const updatedAnswerList = [...answerList];
      updatedAnswerList[existingIndex] = { quesId, optionId };
      setAnswerList(updatedAnswerList);
    } else {
      // Add a new entry
      setAnswerList((prev) => [...prev, { quesId, optionId }]);
    }

    setSelectedOption(optionId);
    // handleToNextQuestion();
  };

  //   Function to render progress bar
  const renderProgressSection = () => {
    return (
      <div className="training_progress_bar_container d-flex align-items-baseline justify-content-center gap-2">
        <p className="progress_badge">
          {currentQuestionIndex + 1} of {trainingQuestionArray.length}
        </p>
        <Progress
          className="w-100"
          style={{ height: "8px" }}
          value={currentQuestionIndex}
          max={trainingQuestionArray.length}
        />
        {/* <X /> */}
      </div>
    );
  };

  //   Function to render questions
  const renderQuestions = () => {
    return (
      <Fragment>
        {trainingQuestionArray?.length && (
          <Fragment>
            {/* Progress bar container */}
            {renderProgressSection()}

            <div className="d-flex flex-column align-items-center justify-content-center">
              <p className="training_question_heading">
                {trainingQuestionArray[currentQuestionIndex]?.question}
              </p>
            </div>
            <div className="d-flex justify-content-between flex-wrap">
              <div
                className={
                  selectedOption === 1
                    ? "highlighted_answer training_question_options mt-20 mb-20"
                    : "training_question_options mt-20 mb-20"
                }
                onClick={() =>
                  handleToSaveAnswer(
                    trainingQuestionArray[currentQuestionIndex]?.questionId,
                    1,
                  )
                }
              >
                {trainingQuestionArray[currentQuestionIndex]?.optionA}
              </div>
              <div
                className={
                  selectedOption === 2
                    ? "highlighted_answer training_question_options mt-20 mb-20"
                    : "training_question_options mt-20 mb-20"
                }
                onClick={() =>
                  handleToSaveAnswer(
                    trainingQuestionArray[currentQuestionIndex]?.questionId,
                    2,
                  )
                }
              >
                {trainingQuestionArray[currentQuestionIndex]?.optionB}
              </div>
              <div
                className={
                  selectedOption === 3
                    ? "highlighted_answer training_question_options mt-20 mb-20"
                    : "training_question_options mt-20 mb-20"
                }
                onClick={() =>
                  handleToSaveAnswer(
                    trainingQuestionArray[currentQuestionIndex]?.questionId,
                    3,
                  )
                }
              >
                {trainingQuestionArray[currentQuestionIndex]?.optionC}
              </div>
              <div
                className={
                  selectedOption === 4
                    ? "highlighted_answer training_question_options mt-20 mb-20"
                    : "training_question_options mt-20 mb-20"
                }
                onClick={() =>
                  handleToSaveAnswer(
                    trainingQuestionArray[currentQuestionIndex]?.questionId,
                    4,
                  )
                }
              >
                {trainingQuestionArray[currentQuestionIndex]?.optionD}
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  };

  //   Function to submit questions
  const handleToSubmit = async () => {
    if (answerList.length === trainingQuestionArray.length) {
      const payload = {};
      payload.questionIds = answerList?.map((item) => item.quesId);
      payload.answerIds = answerList?.map((item) => item.optionId);

      const response = await axiosPost(
        currentExam === "GI_EXAM_PENDING_PAGE"
          ? POST_GI_TRAINING_QUESTION
          : POST_LI_TRAINING_QUESTION,
        payload,
      );
      if (response?.success) {
        setCurrentQuestionIndex(0);
        setAnswerList([]);
        setSubmitFlag(true);
        setTestStatus(response?.data?.status); // pass / failed a/c to api response
        setPercentage(response?.data?.pecentage / 10);
        toast.success(response?.msg);
        if (currentExam !== "GI_EXAM_PENDING_PAGE") setCurrentExam(null);
        setSelectedOption(null);
      } else {
        setAnswerList([]);
        setCurrentQuestionIndex(0);
        setSubmitFlag(true);
        setTestStatus(response?.data?.status); // pass / failed a/c to api response
        setPercentage(response?.data?.pecentage / 10);
        setSelectedOption(null);
        // dispatch(fetchdashboardModalStates({ axiosGet }));
      }
    } else {
      toast.error("Kindly select the option to Submit");
    }
  };

  const getTrainingQuestion = async () =>
    dispatch(
      fetchTrainingModalQuestion({
        axiosGet,
        api:
          currentExam === "GI_EXAM_PENDING_PAGE"
            ? GET_GI_TRAINING_QUESTION
            : GET_LI_TRAINING_QUESTION,
      }),
    );
  //   Function ends here -------------------------------------------------

  useEffect(() => {
    if (currentExam !== null && isQuestionModalOpen) {
      getTrainingQuestion();
      setCurrentQuestionIndex(0);
      setAnswerList([]);
      setRemainingTime(45 * 60 + 2);
    }
  }, [isQuestionModalOpen, currentExam]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setCurrentExam(page);
  }, [page]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    return minutes.toString();
  };

  return (
    <Fragment>
      <CustomModal
        isOpen={isQuestionModalOpen}
        toggle={toggle}
        width={submitFlag ? "526px" : "916px"}
        modalbody={
          submitFlag ? (
            testStatus === "PASSED" ? (
              <div className="d-flex flex-column align-items-center justify-content-center questions_submit_modal">
                <div className="mb-2">
                  <LottiePlayer
                    animationData={testStatus === "PASSED" ? ThumbsUp : SadFace}
                    height={94}
                    width={94}
                  />
                </div>
                <p className="modal_header text-center">{`Congratulations ${name}, you have passed the exam!`}</p>
                <div className="my-3 d-flex align-items-center gap-1">
                  <p className="questions_attempt">No. of correct answers -</p>{" "}
                  <p className="total_score">{percentage}</p>
                </div>
                {currentExam === "GI_EXAM_PENDING_PAGE" && (
                  <CustomButton
                    buttonName="Start Life Insurance Training"
                    handleClick={() => {
                      setCurrentExam("LI_EXAM_PENDING_PAGE");
                      setSubmitFlag(false);
                      setTestStatus(null);
                    }}
                    alignCenter
                  />
                )}
              </div>
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center questions_submit_modal">
                <div className="mb-2">
                  <LottiePlayer
                    animationData={testStatus === "PASSED" ? ThumbsUp : SadFace}
                    height={94}
                    width={94}
                  />
                </div>
                <p className="modal_header text-center">{`Sorry ${name}, you have Failed the exam!`}</p>
                <div className="my-3 d-flex align-items-center gap-1">
                  <p className="questions_attempt">No. of correct answers -</p>{" "}
                  <p className="fail_score">{percentage}</p>
                </div>
                <CustomButton
                  buttonName="Re-take Test"
                  handleClick={() => {
                    setSubmitFlag(false);
                    setTestStatus(null);
                    setCurrentExam(currentExam);
                    setAnswerList([]);
                  }}
                  alignCenter
                />
              </div>
            )
          ) : (
            <Fragment>
              {/* Render question section */}
              {renderQuestions()}

              <hr className="footer_line" />
              <div className="d-flex justify-content-between align-items-center">
                <CustomButton
                  buttonName="Previous"
                  handleClick={
                    currentQuestionIndex > 0
                      ? handleToPreviousQuestion
                      : handleToGoBack
                  }
                  className="training_rule_back_btn"
                  btnNameClass="trainging_rule_back_name"
                  alignCenter
                />

                {/* Show Timer */}
                <CircularProgressBar
                  percentage={45}
                  text={<Fragment>{formatTime(remainingTime)} Mins</Fragment>}
                  maxValue={45}
                />

                <CustomButton
                  buttonName={
                    <>
                      {currentQuestionIndex ===
                      trainingQuestionArray.length - 1 ? (
                        "Submit"
                      ) : (
                        <Fragment>
                          Next <ArrowRight size={24} />
                        </Fragment>
                      )}
                    </>
                  }
                  alignCenter
                  handleClick={
                    currentQuestionIndex === trainingQuestionArray.length - 1
                      ? handleToSubmit
                      : handleToNextQuestion
                  }
                />
              </div>
            </Fragment>
          )
        }
      />
    </Fragment>
  );
};

export default QuestionModal;
