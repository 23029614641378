import React, { useEffect } from "react";
import CertificateHeader from "./CertificateHeader";
import CertificateFooter from "./CertificateFooter";
import BGLogo from "../../assets/images/certificates/BGLogo.svg";

// import "./index.scss";
const CertificatePage = ({ certificateDetails, onRendered }) => {
  useEffect(() => {
    if (onRendered) {
      onRendered();
    }
  }, []);
  return (
    <>
      <div className="printable-page">
        <div className="header">
          <CertificateHeader />
        </div>
        <div className="watermark">
          <img src={BGLogo} alt="logo" style={{ height: "500px" }} />
        </div>
        <div className="page-content">
          <p
            style={{
              textAlign: "center",
              fontWeight: "600",
              fontSize: "16px", 
              marginTop: "10%"
            }}
          >
            Appointment Letter cum E-Certiﬁcate
          </p>

          <p style={{ margin: "20px 0px", fontWeight: "600", fontSize: "12px" }}>
            {certificateDetails.name || " "}
          </p>

          <p style={{ marginBottom: "20px", fontWeight: "600", fontSize: "12px" }}>
            Date: {certificateDetails.certifiedDate}{" "}
          </p>

          <p
            style={{ fontSize: "12px", fontWeight: "400", lineHeight: "20px" }}
          >
            We welcome you to BimaGuide (A brand of In2Fin Insurance Brokers
            Private Limited).
          </p>
          <p
            style={{ fontSize: "12px", fontWeight: "400", lineHeight: "20px" }}
          >
            This is with regard to the request made by you for enrolling as a
            Point of Sales Person.
          </p>

          <p
            style={{ fontSize: "12px", fontWeight: "400", lineHeight: "20px", textAlign: 'justify' }}
          >
            This is to confirm that you have successfully completed the
            prescribed training and have also passed the examination specified
            for Point of Sales Persons conducted by BimaGuide (A brand of In2Fin
            Insurance Brokers Private Limited) under the Guidelines on Point of
            Sales Person for Non-life, Life, and Health Insurers as notified by
            the IRDAI from time to time. Your personal details are as under:
          </p>

          <div className="details">
            <p style={{ margin: "20px 0px", fontWeight: "600", fontSize: "12px" }}>
              POS CODE: {certificateDetails.partnerId}{" "}
            </p>
            <p style={{ margin: "20px 0px", fontWeight: "600", fontSize: "12px" }}>
              PAN: {certificateDetails.panNumber}
            </p>
            <p style={{ margin: "20px 0px", fontWeight: "600", fontSize: "12px" }}>
              DOB: {certificateDetails.dob}{" "}
            </p>
          </div>

          <p
            style={{ fontSize: "12px", fontWeight: "400", lineHeight: "20px", textAlign: 'justify' }}
          >
            This letter authorizes you to act as Point of Sales Person for
            BimaGuide to exclusively market products categorized and identified
            under the Guidelines as notified by the IRDAI. Your appointment is
            subject to the POSP Service Agreement including the Terms of Use,
            Privacy Policy as well as other policies you have agreed with
            BimaGuide.
          </p>

          <p
            style={{ fontSize: "12px", fontWeight: "400", lineHeight: "20px", textAlign: 'justify' }}
          >
            You hereby confirm and undertake that you will always solicit
            insurance business honestly, placing the interest of the clients
            before your own interest. You hereby confirm and undertake that
            during the term of your appointment with BimaGuide, you are not
            associated or shall not associate yourself with any other insurance
            intermediary or insurer in any manner whatsoever. In case you wish
            to work for another company, you are required to obtain a fresh
            letter from the new insurance intermediary/insurer in order to act
            as Point of Sales Person for that entity.
          </p>

          <div className="signature">
            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                marginTop: "8%"
              }}
            >
              Yours Truly,
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "600",
                lineHeight: "20px",
              }}
            >
              Anuj Jain
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
              }}
            >
              Authorized Signatory
            </p>
          </div>

          <p
            style={{
              fontSize: "10px",
              fontWeight: "300",
              lineHeight: "20px",
              alignItems: "center",
              textAlign: "center",
              marginTop: "5%"
            }}
          >
            <em>
              This is an electronically generated letter and requires no
              signature.
            </em>
          </p>
        </div>

        {/* Page break */}
        <div className="page-break"></div>

        <div className="page-content">
          <div className="declaration">
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "20px",
              }}
            >
              Declaration:
            </p>

            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                textAlign: 'justify'
              }}
            >
              I, as a POSP appointed by the BimaGuide (A brand of In2Fin
              Insurance Brokers Private Limited), accept all the terms and
              conditions as provided under the Service Agreement along with the
              terms of use, privacy policy as well as other policies of the
              Company which have been made available to me on the POSP Portal of
              the Company and undertake to comply with the same with integrity
              and honesty.
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                textAlign: 'justify'
              }}
            >
              I hereby declare that all the information provided by me has been
              voluntarily furnished and is true, correct, and complete in
              nature. I shall not hold the Company, its employees, and/or any
              representatives responsible in case of any incorrect information
              provided by me. I agree to notify the Company immediately if there
              is any change in the information provided by me. I shall abide by
              the Company’s policies and decisions with respect to the amount
              payable to me. I agree that the Company reserves the right to
              change the payouts/commission with or without any prior notice. I
              hereby authorize the Company to deduct applicable TDS from the
              payout/commission or any other taxes as applicable under the tax
              laws.
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                textAlign: 'justify'
              }}
            >
              I hereby declare that I have submitted my identity proof(s) to the
              Company voluntarily for the purpose of establishing my identity
              and for enrolling as a Point of Sale Person (POSP) in accordance
              with the applicable legal framework.
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                textAlign: 'justify',
                lineHeight: "20px",
              }}
            >
              I hereby declare that I am not a Related Party to the Company
              under the meaning of the Companies Act, 2013, and any rules
              thereto, and agree to disclose the same forthwith to the Company
              if I become one.
            </p>
            <p
              style={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "20px",
                textAlign: 'justify'
              }}
            >
              <em>
                Disclaimer: The Company reserves the right to change/modify this
                Code of Conduct from time to time. Please check with{" "}
                <a href="https://www.bimaguide.com">
                  https://www.bimaguide.com
                </a>{" "}
                for more details.
              </em>
            </p>
          </div>
        </div>
        <div className="footer">
          <CertificateFooter />
        </div>
      </div>
    </>
  );
};

export default CertificatePage;
