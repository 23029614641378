import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { isMobileDevice } from "../../utils/utils";

const CircularProgressBar = ({ percentage, text, maxValue }) => {
  return (
    <div
      style={{ width: isMobileDevice() ? "49px" : "69px" }}
      className="ml_24"
    >
      <CircularProgressbar
        value={percentage}
        text={text}
        maxValue={maxValue ?? 100}
      />
    </div>
  );
};

export default CircularProgressBar;
