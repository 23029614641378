import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "../../../../../axios/services";
import { Check, Edit2, Eye, X } from "react-feather";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import CustomButton from "../../../../../Components/Button/CustomButton/CustomButton";
import { toast } from "react-hot-toast";
import FormInput from "../../../../../Components/FormInput/FormInput";
import {
  fetchPartnerQuoteMainModalData,
  fetchQuoteRequestData,
} from "../../../store/store";
import {
  POST_ACCEPT_SINGLE_QUOTE_SHARED,
  POST_DECLINE_ALL_QUOTE_SHARED,
  POST_DECLINE_SINGLE_QUOTE_SHARED,
  POST_KYC_DETAILS,
  POST_UPDATE_QUOTE_SHARED,
} from "../../../../../axios/apiendPoint";
import {
  createFormDataWithFileAndJSON,
  filteredData,
  mandatoryDocFields,
  QuoteStatusList,
} from "../../../../../Constants/Constants";
import InspectionDetails from "./InspectionDetails";
import InspectionProgress from "./InspectionProgress";
import InspectionAwaited from "./InspectionAwaited";
import PaymentPending from "./PaymentPending";
import PaymentLinkShared from "./PaymentLinkShared";
import PaymentValidating from "./PaymentValidating";
import QuoteSuccessModal from "./QuoteSuccessModal";
import { ReactComponent as ThreeDotsVertical } from "../../../../../assets/images/common/threedots.svg";
import styles from "./QuoteShared.module.scss";
import MultiSelect from "../../../../../Components/MultiSelect/MultiSelect";
import { ReactComponent as InfoIcon } from "../../../../../assets/images/common/info.svg";
import QuoteDynamicHeader from "../../../../../Components/QuoteDynamicHeader/QuoteDynamicHeader";
import LookingLottie from "../../../../../assets/lottie/looking.json";
import { declineReasonOptions } from "../../../../../Constants/Constants";
import DeclineAllModal from "../../../../../Components/Modal/DeclineAllModal/DeclineAllModal";
import DocumentUpload from "./DocumentUpload";
import KycVerification from "./KycVerification";
import { InsurerLogoMap } from "../../../../../Constants/InsurerLogoMap";

const QuoteShared = () => {
  const dispatch = useDispatch();
  const { axiosGet2, axiosPost2 } = useAxiosPrivate();

  const { axiosFormDataPost2 } = useAxiosPrivate();

  const currentQuote = useSelector(
    (state) => state.quoteRequestReducer.currentQuoteData,
  );
  const params = `quoteId=${currentQuote?.quoteId}`;

  const details = useSelector(
    (state) => state.quoteRequestReducer.partnerQuoteMainModalDetails,
  );
  const {
    offlineQuotes = [],
    quoteState = null,
    isQuoteChangeRequested = false,
    reuploadDocument = [],
    chequeDetails = [],
  } = details || {};

  const isChangeRequestInProcess = offlineQuotes?.some(
    (item) => item.quoteStatus === QuoteStatusList.CHANGE_REQUESTED,
  );
  const [offlineQuotesData, setOfflineQuotesData] = useState([]);
  const [isDeclinedSuccess, setIsDeclinedSuccess] = useState(false);
  // const [chequeData, setChequeData] = useState([]);

  useEffect(() => {
    if (offlineQuotes?.length > 0) {
      let temp = [];
      for (let i = 0; i < offlineQuotes?.length; i++) {
        let obj = { ...offlineQuotes[i] };
        obj.showOptions = false;
        temp.push(obj);
      }
      setOfflineQuotesData(temp);
    }
    if (quoteState === "FILL_KYC_DOCUMENT") {
      if (reuploadDocument?.length > 0) {
        setDocs(reuploadDocument);
      } else if (chequeDetails?.length === 0) {
        setDocs(mandatoryDocFields);
      }
    }
  }, [offlineQuotes, quoteState, reuploadDocument, chequeDetails]);

  const [openFormIndices, setOpenFormIndices] = useState([]);
  const [acceptQuoteFlag, setAcceptQuoteFlag] = useState(false);
  const [declineQuoteFlag, setDeclineQuoteFlag] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [declineAllFlag, setDeclineAllFlag] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [declineReasonError, setDeclineReasonError] = useState("");
  const [docs, setDocs] = useState([]);

  // Function for accept / decline
  const handleToClickQuoteDetails = (index, name) => {
    setOpenFormIndices([index]); // Set only the clicked form to be open
    setAcceptQuoteFlag(name === "changes");
    setDeclineQuoteFlag(name === "decline");

    let itemData = offlineQuotesData[index];
    setValue("addOns", itemData.addOns || []);
  };

  const changeQuoteSchema = yup.object().shape({
    idv: yup.string().required("IDV is required"),
    ncb: yup
      .number("NCB is Required")
      .typeError("NCB is Required")
      .required("NCB is required")
      .min(0, "NCB can't be less than 0")
      .max(100, "NCB can't be more than 100"),
    // addOns: yup.string().required("Add On is required"),
    discount: yup
      .number("Discount is Required")
      .typeError("Discount is Required")
      .required("Discount is required")
      .min(0, "Discount can't be less than 0")
      .max(100, "Discount can't be more than 100"),
  });

  const declineQuoteSchema = yup.object().shape({
    declineReason: yup.string().required("Decline reason is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(
      acceptQuoteFlag ? changeQuoteSchema : declineQuoteSchema,
    ),
    defaultValues: {
      idv: "",
      ncb: "",
      addOns: "",
      discount: "",
      declineReason: "",
    },
  });

  // Handle to reset
  const handleReset = () => {
    reset();
    setOpenFormIndices([]);
    setAcceptQuoteFlag(false);
    setDeclineQuoteFlag(false);
    setSelectedQuote(null);
  };

  // Handle to submit Accept one quote
  const handleToSubmitAcceptSingleQuote = async (data) => {
    const payload = { quoteRequestId: data?.quoteRequestId };
    const response = await axiosPost2(POST_ACCEPT_SINGLE_QUOTE_SHARED, payload);
    if (response?.success) {
      toast.success(response?.msg);
      handleReset();
      dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));
      dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));
    }
  };

  // Handle to submit decline one quote
  const handleToSubmitDeclineSingleQuote = async (data) => {
    const payload = { quoteRequestId: selectedQuote?.quoteRequestId, ...data };
    const response = await axiosPost2(
      POST_DECLINE_SINGLE_QUOTE_SHARED,
      payload,
    );
    if (response?.success) {
      toast.success(response?.msg);
      handleReset();
      dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));
      dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));
    }
  };

  // Handle to submit update quote
  const handleToSubmitUpdateQuote = async (data) => {
    // to be removed later ------------------
    const falseData = {};
    falseData.declineReason = data.declineReason;
    falseData.idv = data.idv.replace(/,/g, "");
    falseData.ncb = data.ncb;
    falseData.discount = data.discount;
    if (data.addOns) falseData.addOns = [data.addOns];
    if (data.remarks) falseData.remarks = data.remarks;
    // to be removed later ------------------

    const payload = {
      quoteRequestId: selectedQuote?.quoteRequestId,
      ...falseData,
    };
    const response = await axiosPost2(POST_UPDATE_QUOTE_SHARED, payload);
    if (response?.success) {
      toast.success(response?.msg);
      handleReset();
      dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));
      dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));
    }
  };

  const openDeclineAllModal = () => {
    setDeclineAllFlag(true);
  };

  // Handle To decline all quotes
  const handleToDeclineAllQuotes = async () => {
    if (!declineReason || declineReason === "") {
      setDeclineReasonError("Decline reason is required");
      return;
    } else {
      setDeclineReasonError("");
    }

    const payload = {
      quoteId: currentQuote?.quoteId,
      declineReason: declineReason,
    };
    const response = await axiosPost2(POST_DECLINE_ALL_QUOTE_SHARED, payload);
    if (response?.success) {
      toast.success(response?.msg);
      handleReset();
      setIsDeclinedSuccess(true);
      dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));
      dispatch(fetchQuoteRequestData({ axiosGet: axiosGet2 }));
    }
  };

  const handleOptionsRender = (item, index) => {
    let temp = [...offlineQuotesData];
    temp[index].showOptions = true;
    setOfflineQuotesData(temp);
  };

  const handleViewAllQuotesButton = () => {
    let downloadLinks = offlineQuotesData?.map((item) => {
      return item?.quoteUrl;
    });

    downloadLinks = downloadLinks.filter((item) => {
      return item !== undefined && item !== null && item !== "";
    });

    let downloadHrefsbutton = document.createElement("a");

    for (let i = 0; i < downloadLinks.length; i++) {
      downloadHrefsbutton.href = downloadLinks[i];
      downloadHrefsbutton.target = "_blank";
      downloadHrefsbutton.click();
    }
  };

  const handleKYCSubmit = async (data) => {
    const params = `quoteId=${currentQuote?.quoteId}`;
    let { chequeDetails: chequeToUpload, ...rest } = data;
    // add FILE as per index in the array and previousChequeNumber chequeDetails already have cheque
    let chequeData = [];

    for (let index = 0; index < chequeToUpload.length; index++) {
      let item = chequeToUpload[index];
      if (!item.chequeStatus || item.chequeStatus !== "VERIFIED") {
        let amount = item.amount.replace(/,/g, "");
        let newCheque = {
          chequeNumber: item.chequeNumber,
          bankName: item.bankName,
          issueDate: item.issueDate,
          amount: amount,
        };
        if (chequeDetails[index]?.chequeNumber)
          newCheque.previousChequeNumber = chequeDetails[index]?.chequeNumber;

        chequeData.push(newCheque);
      }
    }

    const finalData = {
      ...rest,
      quoteId: currentQuote?.quoteId,
    };
    if (chequeData && chequeData.length > 0) {
      finalData.chequeDetailsList = chequeData;
    }
    if (
      chequeToUpload &&
      chequeToUpload[0] &&
      (chequeToUpload[0]?.chequeStatus !== "VERIFIED" ||
        !chequeToUpload[0]?.chequeStatus)
    ) {
      if (chequeToUpload[0]?.chequeFile)
        finalData.CHEQUE_ONE = chequeToUpload[0]?.chequeFile;
    }
    if (
      chequeToUpload &&
      chequeToUpload[1] &&
      (chequeToUpload[1]?.chequeStatus !== "VERIFIED" ||
        !chequeToUpload[1]?.chequeStatus)
    ) {
      if (chequeToUpload[1]?.chequeFile)
        finalData.CHEQUE_TWO = chequeToUpload[1]?.chequeFile;
    }
    let formData = createFormDataWithFileAndJSON(finalData);

    const response = await axiosFormDataPost2(POST_KYC_DETAILS, formData);
    if (response?.success) {
      toast.success(response?.msg);
      dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));
    }
  };

  const onSubmit = (data) => {
    const finalData = filteredData(data);

    if (declineQuoteFlag && selectedQuote !== null)
      handleToSubmitDeclineSingleQuote(finalData);

    if (acceptQuoteFlag && selectedQuote !== null)
      handleToSubmitUpdateQuote(finalData);
  };

  return (
    <div className="quote_modal_container">
      {/* Quote Shared form */}
      <div className="quote_request_shared_quote_container">
        <div className="d_flex  align_center justify_space_between mb_12">
          <p className="component_section_name mt_12">Shared Quotes</p>
          {quoteState === "QUOTE_SHARED" && (
            <div className="quote_request_shared_all_quote_action">
              <span
                className="cursor_pointer text-end view_all_quotes"
                onClick={handleViewAllQuotesButton}
              >
                View All Quotes
              </span>
              <span
                className="cursor_pointer text-end decline_text"
                onClick={openDeclineAllModal}
              >
                Decline All
              </span>
            </div>
          )}
        </div>
        {/*  ${item.quoteStatus === QuoteStatusList.ACCEPTED || item.quoteStatus === QuoteStatusList.ACCEPTED_WITH_INSPECTION ? "quote_accepted_card" : ""}
              ${item.quoteStatus === QuoteStatusList.DECLINED ? "quote_declined_card" : ""}
              ${item.quoteStatus === QuoteStatusList.UNAVAILABLE ? "quote_unavailable_card" : ""}
              ${item.quoteStatus === QuoteStatusList.CHANGE_REQUESTED ? "quote_change_requested_card" : ""} 
              line 298
              */}
        <div className="quote_request_shared_quote_list">
          {quoteState !== "INSPECTION_PORTAL_REQUEST_RAISE" &&
            offlineQuotesData?.map((item, index) => {
              const isFormOpen = openFormIndices.includes(index);

              return (
                <div className="quote_card_container" key={index}>
                  <div
                    className={`quote_card
${item.quoteStatus === QuoteStatusList.ACCEPTED || item.quoteStatus === QuoteStatusList.ACCEPTED_WITH_INSPECTION ? "quote_accepted_card" : ""}
              ${item.quoteStatus === QuoteStatusList.DECLINED ? "quote_declined_card" : ""}
              ${item.quoteStatus === QuoteStatusList.UNAVAILABLE ? "quote_unavailable_card" : ""}
              ${item.quoteStatus === QuoteStatusList.CHANGE_REQUESTED ? "quote_change_requested_card" : ""}
              ${isFormOpen && acceptQuoteFlag ? "is_change_open" : ""}
              ${isFormOpen && declineQuoteFlag ? "quote_declined_card" : ""}
              ${item.addOns.length === 0 ? "quote_decline_border_radius" : ""}
              `}
                  >
                    <div className="quote_card_main">
                      <div className="quote_brand_name d-flex flex-wrap align-items-center gap-4">
                        {item.insurerId && InsurerLogoMap[item.insurerId] ? (
                          <img
                            src={InsurerLogoMap[item.insurerId]?.Logo}
                            alt={index}
                            className="logo_image"
                          />
                        ) : (
                          <div className="logo_name">{item.insurerName}</div>
                        )}
                        {(item.quoteStatus === "ACCEPTED_WITH_INSPECTION" ||
                          item.quoteStatus === "ACCEPTED") && (
                          <div className="quote_card_badge quote_accepted_badge">
                            <Check />{" "}
                            {item.quoteStatus === "ACCEPTED_WITH_INSPECTION"
                              ? "Quote Accepted with inspection"
                              : "Quote Accepted"}
                          </div>
                        )}
                      </div>
                      <div className="d-flex flex-wrap align-items-center justify-content-between">
                        <div className="quote_status_btns">
                          {[
                            QuoteStatusList.ACCEPTED,
                            QuoteStatusList.SHARED,
                            QuoteStatusList.ACCEPTED_WITH_INSPECTION,
                          ].includes(item.quoteStatus) && (
                            <>
                              <div className="view_quote_btn">
                                <a
                                  href={item?.quoteUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Eye size={16} />
                                  &nbsp; View Quote
                                </a>
                              </div>
                              {!isFormOpen &&
                                !item.inspectionRequired &&
                                ![
                                  QuoteStatusList.ACCEPTED,
                                  QuoteStatusList.ACCEPTED_WITH_INSPECTION,
                                ].includes(item.quoteStatus) && (
                                  <button
                                    className="option_btn accept_btn"
                                    onClick={() =>
                                      handleToSubmitAcceptSingleQuote(item)
                                    }
                                    disabled={isChangeRequestInProcess}
                                  >
                                    <Check size={16} /> Accept
                                  </button>
                                )}
                              {!isFormOpen &&
                                item.inspectionRequired &&
                                ![
                                  QuoteStatusList.ACCEPTED,
                                  QuoteStatusList.ACCEPTED_WITH_INSPECTION,
                                ].includes(item.quoteStatus) && (
                                  <button
                                    className="option_btn accept_btn"
                                    onClick={() =>
                                      handleToSubmitAcceptSingleQuote(item)
                                    }
                                    disabled={isChangeRequestInProcess}
                                  >
                                    <Check size={16} /> Accept with inspection
                                  </button>
                                )}
                              {!isFormOpen &&
                                ![
                                  QuoteStatusList.ACCEPTED,
                                  QuoteStatusList.ACCEPTED_WITH_INSPECTION,
                                ].includes(item.quoteStatus) && (
                                  <>
                                    {!item.showOptions ? (
                                      <div
                                        className={styles.multiple_options_btn}
                                        onClick={() =>
                                          handleOptionsRender(item, index)
                                        }
                                      >
                                        <ThreeDotsVertical size={16} />
                                        <div className={styles.options_text}>
                                          Options
                                        </div>
                                      </div>
                                    ) : (
                                      <>
                                        {!isQuoteChangeRequested && (
                                          <button
                                            className="option_btn changes_btn"
                                            onClick={() => {
                                              handleToClickQuoteDetails(
                                                index,
                                                "changes",
                                              );
                                              setSelectedQuote(item);
                                            }}
                                          >
                                            <Edit2 size={16} /> Changes
                                          </button>
                                        )}
                                        <button
                                          className="option_btn changes_btn"
                                          onClick={() => {
                                            handleToClickQuoteDetails(
                                              index,
                                              "decline",
                                            );
                                            setSelectedQuote(item);
                                          }}
                                          disabled={isChangeRequestInProcess}
                                        >
                                          <X size={16} /> Decline
                                        </button>
                                      </>
                                    )}
                                  </>
                                )}
                            </>
                          )}
                          {item.quoteStatus === QuoteStatusList.UNAVAILABLE && (
                            <div className="quote_not_found_btn">
                              {item.quoteStatusName}
                            </div>
                          )}
                          {item.quoteStatus === QuoteStatusList.DECLINED && (
                            // <div className="quote_not_found_btn">
                            //   {item.quoteStatusName}
                            // </div>
                            <div className="quote_card_badge quote_rejected_badge">
                              <X /> {item.quoteStatusName}
                            </div>
                          )}
                          {item.quoteStatus ===
                            QuoteStatusList.CHANGE_REQUESTED && (
                            <div className="quote_change_requested_btn">
                              {item.quoteStatusName}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {isFormOpen && (
                      <form
                        className="quote_form_container"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        {acceptQuoteFlag && openFormIndices.includes(index) && (
                          <div className="quote_form row">
                            <p className="heading">Request Changes</p>
                            <div className="my-3 col-6">
                              <Controller
                                control={control}
                                name={`idv`}
                                render={({ field }) => (
                                  <FormInput
                                    field={field}
                                    inputMode="currency"
                                    placeholder="Enter IDV"
                                    maxLength={10}
                                  />
                                )}
                              />
                              {errors.idv && (
                                <div className="error_class">
                                  {errors.idv.message}
                                </div>
                              )}
                            </div>

                            <div className="my-3 col-6">
                              <Controller
                                control={control}
                                name={`ncb`}
                                render={({ field }) => (
                                  <FormInput
                                    inputMode="numeric"
                                    field={field}
                                    placeholder="Enter NCB %"
                                  />
                                )}
                              />
                              {errors.ncb && (
                                <div className="error_class">
                                  {errors.ncb.message}
                                </div>
                              )}
                            </div>

                            <div className="my-3 col-6">
                              <Controller
                                control={control}
                                name={`addOns`}
                                render={({ field }) => {
                                  return (
                                    <MultiSelect
                                      {...field}
                                      onChange={(selected) => {
                                        field.onChange(
                                          selected
                                            ? selected.map(
                                                (option) => option.value,
                                              )
                                            : [],
                                        );
                                      }}
                                      placeholder="Select Add Ons"
                                      isSearchable
                                      isMulti
                                      options={
                                        item.totalAddOns &&
                                        item.totalAddOns.map((item) => ({
                                          label: item,
                                          value: item,
                                        }))
                                      }
                                      className="react_select"
                                      value={
                                        field.value &&
                                        field.value.map((item) => ({
                                          label: item,
                                          value: item,
                                        }))
                                      }
                                    />
                                  );
                                }}
                              />
                              {errors.addOns && (
                                <div className="error_class">
                                  {errors.addOns.message}
                                </div>
                              )}
                            </div>

                            <div className="my-3 col-6">
                              <Controller
                                control={control}
                                name={`discount`}
                                render={({ field }) => (
                                  <FormInput
                                    inputMode="decimal"
                                    field={field}
                                    placeholder="Enter Discount"
                                  />
                                )}
                              />
                              {errors.discount && (
                                <div className="error_class">
                                  {errors.discount.message}
                                </div>
                              )}
                            </div>

                            <div className="d-flex align-items-center justify-content-between m3-3 mb-5">
                              <CustomButton
                                buttonName="CANCEL"
                                handleClick={handleReset}
                                alignCenter
                                className="cancel_btn"
                                btnNameClass="cancel_btn_name"
                              />

                              <div className="change_only_once_text">
                                <InfoIcon
                                  id="TooltipExample"
                                  width={16}
                                  height={16}
                                />
                                You can request changes only once for all quotes
                              </div>
                              <CustomButton type="submit" alignCenter />
                            </div>
                          </div>
                        )}

                        {declineQuoteFlag &&
                          openFormIndices.includes(index) && (
                            <div className="quote_form">
                              <p className="heading">Decline Quote</p>
                              <div className="my-3">
                                <Controller
                                  control={control}
                                  name={`declineReason`}
                                  render={({ field }) => (
                                    <MultiSelect
                                      {...field}
                                      onChange={(e) => {
                                        field.onChange(e ? e.value : null);
                                      }}
                                      placeholder="Select Decline Reason"
                                      isSearchable
                                      isMulti={false}
                                      options={declineReasonOptions}
                                      className="react_select"
                                      value={declineReasonOptions.filter(
                                        (option) =>
                                          option.value === field.value,
                                      )}
                                    />
                                  )}
                                />
                                {errors.declineReason && (
                                  <div className="error_class">
                                    {errors.declineReason.message}
                                  </div>
                                )}
                              </div>

                              <div className="d-flex align-items-center justify-content-between m3-3 mb-5">
                                <CustomButton
                                  buttonName="CANCEL"
                                  handleClick={handleReset}
                                  alignCenter
                                  className="cancel_btn"
                                  btnNameClass="cancel_btn_name"
                                />
                                <CustomButton
                                  type="submit"
                                  alignCenter
                                  buttonName="Decline"
                                  className="decline_btn"
                                  btnNameClass="decline_btn_name"
                                />
                              </div>
                            </div>
                          )}
                      </form>
                    )}

                    {item.quoteStatus === QuoteStatusList.CHANGE_REQUESTED && (
                      <QuoteDynamicHeader
                        title="Our Team is working on the requested changes"
                        subTitle="We will update you shortly on the status"
                        noCard
                        customImage
                        lottieImage={LookingLottie}
                      />
                    )}
                  </div>
                  {/* addons list */}
                  {item.addOns && item.addOns.length > 0 && (
                    <div className="quote_addons_list">
                      {/* string concat */}
                      <strong>Addons:</strong>
                      &nbsp;
                      {item.addOns.join(", ")}
                    </div>
                  )}
                </div>
              );
            })}

          {quoteState === "INSPECTION_PORTAL_REQUEST_RAISE" &&
            offlineQuotes?.map((item, index) => {
              return (
                <div className="quote_card_container" key={index}>
                  <div
                    key={index}
                    className={
                      item.quoteStatus === "ACCEPTED_WITH_INSPECTION" ||
                      item.quoteStatus === "ACCEPTED"
                        ? "quote_card"
                        : "quote_declined_card quote_card"
                    }
                  >
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <div className="d-flex flex-wrap align-items-center gap-4">
                        {item.insurerId && InsurerLogoMap[item.insurerId] ? (
                          <img
                            src={InsurerLogoMap[item.insurerId]?.Logo}
                            alt={index}
                            className="logo_image"
                          />
                        ) : (
                          <div className="logo_name">{item.insurerName}</div>
                        )}
                        {(item.quoteStatus === "ACCEPTED_WITH_INSPECTION" ||
                          item.quoteStatus === "ACCEPTED") && (
                          <div className="quote_card_badge quote_accepted_badge">
                            <Check />{" "}
                            {item.quoteStatus === "ACCEPTED_WITH_INSPECTION"
                              ? "Quote Accepted with inspection"
                              : "Quote Accepted"}
                          </div>
                        )}
                      </div>

                      <div className="quote_status_btn">
                        {(item.quoteStatus === "ACCEPTED_WITH_INSPECTION" ||
                          item.quoteStatus === "ACCEPTED") && (
                          <div
                            className={
                              item.quoteStatus === "ACCEPTED_WITH_INSPECTION" ||
                              item.quoteStatus === "ACCEPTED"
                                ? "view_quote_btn"
                                : "decline_view_quote_btn"
                            }
                          >
                            <a
                              href={item?.quoteUrl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <Eye size={16} />
                              &nbsp; View Quote
                            </a>
                          </div>
                        )}
                        {(item.quoteStatus === "UNAVAILABLE" ||
                          item.quoteStatus === "DECLINED") && (
                          <div className="quote_not_found_btn">
                            {item.quoteStatusName}
                          </div>
                        )}
                        {/* {item.quoteStatus === "DECLINED" && (
                          <div className="quote_card_badge quote_rejected_badge">
                             <Check /> {item.quoteStatusName} 

                          </div>
                        )} */}
                      </div>
                    </div>
                  </div>
                  {/* addons list */}
                  {item.addOns && item.addOns.length > 0 && (
                    <span className="quote_addons_list">
                      {/* string concat */}

                      <strong>Addons:</strong>
                      {item.addOns.join(", ")}
                    </span>
                  )}
                </div>
              );
            })}
        </div>
      </div>
      {/* Fill Inspection Details form */}
      {/* {inspectionFlag && <InspectionDetails />} */}
      {/* Fill Inspection Details form */}
      {quoteState === "FILL_CUSTOMER_DETAILS" && <InspectionDetails />}
      {/* Inspection Awaited screen */}
      {quoteState === "INSPECTION_PORTAL_REQUEST_RAISE" && (
        <InspectionAwaited />
      )}
      {/* Inspection Progress screen */}
      {quoteState === "INSPECTION_DETAILS_SHARED" && <InspectionProgress />}
      {/* Payment Link Pending */}
      {quoteState === "PAYMENT_LINK_PENDING" && <PaymentPending />}
      {/* Payment Link Shared */}
      {quoteState === "PAYMENT_LINK_SHARED" && <PaymentLinkShared />}
      {/* Payment details taken */}
      {quoteState === "PAYMENT_PROOF_TAKEN" && <PaymentValidating />}
      {/* Payment details taken */}
      {quoteState === "PAYMENT_DETAILS_APPROVED" && (
        <QuoteSuccessModal
          title={"Payment Received Successfully!"}
          subTitle={"Sit and relax while we map your policy."}
        />
      )}
      {quoteState === "UPLOAD_POLICY_COPY" && (
        <QuoteSuccessModal
          title={"We will soon upload your policy."}
          subTitle={""}
        />
      )}
      {quoteState === "FILL_KYC_DOCUMENT" && (
        <DocumentUpload
          docs={docs}
          onSubmit={handleKYCSubmit}
          setDocs={setDocs}
          chequeDetails={chequeDetails}
        />
      )}
      {quoteState === "VERIFY_KYC_DOCUMENT" && <KycVerification />}
      {/* Decline All Modal */}
      {declineAllFlag && (
        <DeclineAllModal
          declineAllFlag={declineAllFlag}
          setDeclineAllFlag={setDeclineAllFlag}
          declineReasonOptions={declineReasonOptions}
          declineReason={declineReason}
          setDeclineReason={setDeclineReason}
          declineReasonError={declineReasonError}
          handleToDeclineAllQuotes={handleToDeclineAllQuotes}
          isDeclinedSuccess={isDeclinedSuccess}
        />
      )}
    </div>
  );
};

export default QuoteShared;
