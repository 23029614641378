/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./index.scss";
import { setCurrentQuoteData, toggleQuoteDetailModal } from "./store/store";
import Message from "../../assets/lottie/Message.json";
import { useDispatch } from "react-redux";
import QuoteRequestHoverItems from "./QuoteRequestHoverItems";
import {
  getQuoteRequestColor,
  quoteRequestListDate,
  productTypeImageObj,
} from "../../Constants/Constants";
import { Fragment } from "react";
import DocUploadModal from "./Modals/DocUploadModal";
import FillDetailsModal from "./Modals/FillDetailsModal";
import ViewQuoteModal from "./Modals/ViewQuoteModal";
import QuoteDetailModal from "./Modals/QuoteDetailModal/QuoteDetailModal";
import LottiePlayer from "../../Components/LottiePlayer/LottiePlayer";

const QuoteTable = ({ data, isLoading }) => {
  const dispatch = useDispatch();

  // toggle view quote detail modal
  const handleToToggleQuoteDetailModal = () =>
    dispatch(toggleQuoteDetailModal());

  return (
    <Fragment>
      {/* Table Component */}
      <div className="mt_20 quote_request_table_container">
        {data?.length > 0 &&
          data?.map((item, index) => (
            <div
              className="quote_table_card cursor_pointer"
              key={index}
              onClick={() => {
                handleToToggleQuoteDetailModal();
                dispatch(setCurrentQuoteData(item));
              }}
            >
              {/* First Column */}
              <div className="veichel_content d-flex gap-4 align-items-center justify-content-start">
                {/* <img src={item.quoteType === "car" ? Car : Car} alt="veichle" /> */}
                <div className="vehicle_image">
                  {productTypeImageObj[item.productType]}
                </div>
                <div>
                  <p className="p-0 m-0 veichle_number">
                    {item.registrationNumber}
                  </p>
                  <p className="p-0 m-0 owner_name">{item.customerName}</p>
                </div>
              </div>

              {/* Second Column */}
              <div className="ticket_content d-flex align-items-center justify-content-center flex-column">
                <p className="p-0 m-0 ticket_heading">Ticket ID</p>
                <p className="p-0 m-0 ticket_id">{item.quoteId}</p>
              </div>

              {/* Third Column */}
              <div className="status_content">
                <p
                  className="status_badge mb-0"
                  style={{ background: getQuoteRequestColor(item.quoteState) }}
                >
                  {item.quoteStateName}
                </p>
              </div>

              {/* Fourth Column */}
              {/* Without Hover */}
              <div className="date_content">
                {quoteRequestListDate(item.quoteCreationTime) ===
                  "Created Today" && (
                  <LottiePlayer
                    animationData={Message}
                    height={50}
                    width={50}
                    className="m-0 p-0"
                  />
                )}
                <p className="date mb-0">
                  {quoteRequestListDate(item.quoteCreationTime)}
                </p>
              </div>

              {/* With Hover */}
              <QuoteRequestHoverItems item={item} />
            </div>
          ))}

        {/* No Data */}
        {data?.length === 0 && !isLoading && (
          <div className="no_data_container">
            <p className="p-0 m-0">No Data Found</p>
          </div>
        )}

        {/* Pagination Todo */}
        {/* <div className="pagination_container">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={450}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div> */}
      </div>

      {/* Modal importing */}
      <DocUploadModal />

      <FillDetailsModal />

      <ViewQuoteModal />

      <QuoteDetailModal />
    </Fragment>
  );
};

export default QuoteTable;
