import React, { useEffect } from "react";
import "./index.scss";
import CustomModal from "../../../../Components/Modal/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPartnerQuoteMainModalData,
  toggleQuoteDetailModal,
} from "../../store/store";
import useAxiosPrivate from "../../../../axios/services";
import QuoteActivity from "./QuoteActivity";
import QuoteHeader from "./QuoteHeader";
import RequestedDetails from "./RequestedDetails";
import QuoteDocuments from "./QuoteDocuments";
import InternalQuoteSection from "./InternalQuoteSection";

const QuoteDetailModal = () => {
  const dispatch = useDispatch();
  const { axiosGet2 } = useAxiosPrivate();

  const isOpen = useSelector(
    (state) => state.quoteRequestReducer.showQuoteDetailModal,
  );
  const currentQuote = useSelector(
    (state) => state.quoteRequestReducer.currentQuoteData,
  );
  const details = useSelector(
    (state) => state.quoteRequestReducer.partnerQuoteMainModalDetails,
  );

  const { header = {} } = details || {};

  const toggle = () => dispatch(toggleQuoteDetailModal());

  //Fetch quote request list
  const getSharedQuoteDetails = (params) =>
    dispatch(fetchPartnerQuoteMainModalData({ axiosGet: axiosGet2, params }));

  useEffect(() => {
    if (isOpen) {
      const params = `quoteId=${currentQuote?.quoteId}`;
      getSharedQuoteDetails(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuote?.quoteId, isOpen]);

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      width="80vw"
      // height="548px"
      modalsize="xl"
      modaltitle={null}
      noheadertoggle="true"
      nopadding="true"
      headerrow={<QuoteHeader header={header} />}
      modalbody={
        <div className="view_quote_details_container">
          {/* Left Section */}
          <div className="request_details_container">
            <div className="py_24">
              <RequestedDetails />
            </div>
            <div className="py_24">
              <QuoteDocuments />
            </div>
          </div>

          {/* Middle Section */}
          <div className="shared_quote_section">
            <InternalQuoteSection />
          </div>

          {/* Right Section */}
          <div className="activity_container">
            <QuoteActivity />
          </div>
        </div>
      }
    />
  );
};

export default QuoteDetailModal;
