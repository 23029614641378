import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "./Pagination.scss";

export const CustomPagination = ({
  currentPage,
  totalPage,
  handlePageChange,
  pageRangeDisplayed = 5,
}) => {
  const pagesToBeDisplayed = Array.from(
    { length: pageRangeDisplayed },
    (_, index) => {
      let startNumber =
        currentPage - Math.floor(pageRangeDisplayed / 2) + index;

      if (currentPage <= Math.ceil(pageRangeDisplayed / 2)) {
        startNumber = index + 1;
      } else if (currentPage > totalPage - Math.floor(pageRangeDisplayed / 2)) {
        startNumber = totalPage - pageRangeDisplayed + index + 1;
      }

      return startNumber;
    },
  );

  return (
    <div className="pagination_container">
      <Pagination
        currentPage={currentPage}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={handlePageChange}
        className="pagination"
        listClassName="pagination_list"
      >
        <PaginationItem>
          <PaginationLink
            className="pagination_item_nav"
            first
            disabled={currentPage === 1}
            onClick={() => handlePageChange(1)}
          />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            className="pagination_item_nav"
            previous
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          />
        </PaginationItem>
        {pagesToBeDisplayed.map((pageNumber) => {
          if (pageNumber <= totalPage && pageNumber > 0) {
            return (
              <PaginationItem
                key={pageNumber}
                active={pageNumber === currentPage}
              >
                <PaginationLink
                  className="pagination_item"
                  onClick={() => handlePageChange(pageNumber)}
                >
                  {pageNumber}
                </PaginationLink>
              </PaginationItem>
            );
          }
          return null;
        })}
        <PaginationItem>
          <PaginationLink
            className="pagination_item_nav"
            next
            disabled={currentPage === totalPage}
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </PaginationItem>
        <PaginationItem>
          <PaginationLink
            className="pagination_item_nav"
            last
            disabled={currentPage === totalPage}
            onClick={() => handlePageChange(totalPage)}
          />
        </PaginationItem>
      </Pagination>
    </div>
  );
};
