/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import ModalSection from "./ModalSection";
import { useDispatch, useSelector } from "react-redux";
import { setIsDocumentVerified } from "./dashboardStore/store";
import TrainingSection from "./TrainingSection";
import DashboardData from "./DashboardData";
import LockScreen from "./DashboardData/LockScreen";
const Dashboard = () => {
  const dispatch = useDispatch();

  const { modalStateDetails, isPartnerRegistered } = useSelector(
    (state) => state.dashboardModalReducer,
  );
  const { page } = modalStateDetails?.data || {};
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (
      [
        "COURSE_PAGE",
        "COURSE_STARTED",
        "COURSE_VIEWED",
        "TRAINING_STARTED_PAGE",
        "GI_EXAM_PENDING_PAGE",
        "LI_EXAM_PENDING_PAGE",
      ].includes(page)
    )
      dispatch(setIsDocumentVerified());

    if (page === "PROFILE_COMPLETED_PAGE") {
      setCompleted(true);
    }
  }, [page]);

  return (
    <Fragment>
      {!completed && (
        <div
          className="dashboard-top "
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginBottom: "12px",
          }}
        >
          {/* Documents Modal Section */}
          <ModalSection />
          {/* Training Modal Section */}
          <TrainingSection />
        </div>
      )}
      <div
        className="dashboard-wrapper"
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          // marginTop: "12px",
        }}
      >
        <DashboardData />
        {!isPartnerRegistered && <LockScreen />}
      </div>
    </Fragment>
  );
};

export default Dashboard;
