import React from "react";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import BlurOverlay from "../BlurOverlay/BlurOverlay";


const GlobalLoader = () => {
    const isLoading = useSelector((state) => state.loaderReducer.isLoading);
    if (!isLoading) return null;

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw", // Full width of the viewport
                    height: "100vh", // Full height of the viewport
                    zIndex: 9999, // Ensure the loader overlay is on top of everything else
                }}
            >
                <Loader />
                <BlurOverlay
                    isBlur={true}
                />
            </div>
        </>
    );
};

export default GlobalLoader;