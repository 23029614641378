// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_BOOKING_LIST,
  GET_BOOKING_MODAL_DATA,
} from "../../../axios/apiendPoint";

const initialState = {
  bookingList: [],
  pagination: {
    curPage: 1,
    totalPage: 0,
  },
  bookingBucket: {
    curBucket: "",
    bucketList: [],
  },
  modalData: {
    headerData: {},
    vehicleDetails: [],
    policyDetails: [],
    premiumDetails: {},
    policyCopyUrl: "",
    businessType: "",
  },
  loading: true,
  isModalLoading: false,
  error: null,
};

export const fetchBookingModalData = createAsyncThunk(
  "bookingRequest/fetchBookingModalData",
  async (thunkparam) => {
    const { axiosGet, searchParams } = thunkparam;
    const response = await axiosGet(GET_BOOKING_MODAL_DATA, searchParams);
    return { data: response?.data };
  },
);

// Fetch Booking LISTING data
export const fetchBookingData = createAsyncThunk(
  "bookingRequest/fetchBookingData",
  async (thunkparam) => {
    const { axiosGet, searchParams } = thunkparam;
    const response = await axiosGet(GET_BOOKING_LIST, searchParams);
    return {
      bookingList: response?.data?.bookingList,
      bookingBucket: response?.data?.bookingBucket,
      pagination: response?.data?.pagination,
    };
  },
);

export const bookingDataSlice = createSlice({
  name: "bookingRequest",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBookingData.fulfilled, (state, action) => {
      state.bookingList = action?.payload.bookingList;
      state.bookingBucket = action?.payload.bookingBucket;
      state.pagination = action?.payload.pagination;
      state.loading = false;
    });
    builder.addCase(fetchBookingData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBookingData.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchBookingModalData.fulfilled, (state, action) => {
      state.modalData = action?.payload?.data;
      state.isModalLoading = false;
    });
    builder.addCase(fetchBookingModalData.pending, (state) => {
      state.isModalLoading = true;
    });
    builder.addCase(fetchBookingModalData.rejected, (state) => {
      state.isModalLoading = false;
    });
  },
});
export default bookingDataSlice.reducer;
