import React from "react";
import PolicyDealsHeader from "./PolicyDealsHeader";
import PolicyOfferCard from "../../../Components/PolicyOfferCard/PolicyOfferCard";
import Notes from "../../../Components/Notes/Notes";
import DealsListing from "../DealsListing/DealsListing";
import { useSelector } from "react-redux";
import "./PolicyDeals.scss";

const PolicyDeals = ({
  policyHeaderData,
  policyOffersData,
  handleChangeFavourite,
}) => {
  const notesFavourties = useSelector((state) => state.gridReducer.notes);

  return (
    <div className="policy-deals-container">
      <PolicyDealsHeader data={policyHeaderData} />
      {policyHeaderData.date && (
        <div className="policy-deals-date">{policyHeaderData.date}</div>
      )}
      <DealsListing>
        {policyOffersData?.declineDeals?.length > 0 &&
          policyOffersData.declineDeals.map((deal, index) => (
            <div className="deals-list-item" key={`decline-deals-${index}`}>
              <PolicyOfferCard
                index={index}
                key={`decline_deal_${index}`}
                policyDetails={deal}
                dealType="decline_deal"
                handleChangeFavourite={handleChangeFavourite}
              />
            </div>
          ))}

        {policyOffersData?.specialDeals?.length > 0 &&
          policyOffersData.specialDeals.map((deal, index) => (
            <div className="deals-list-item" key={`special-deals-${index}`}>
              <PolicyOfferCard
                index={index}
                key={`special_deal_${index}`}
                policyDetails={deal}
                dealType="special_deal"
                handleChangeFavourite={handleChangeFavourite}
              />
            </div>
          ))}

        {policyOffersData?.marketDeals?.length > 0 &&
          policyOffersData?.marketDeals.map((deal, index) => (
            <div className="deals-list-item" key={`market-deals-${index}`}>
              <PolicyOfferCard
                index={index}
                key={`market_deal_${index}`}
                policyDetails={deal}
                dealType="market_deal"
                handleChangeFavourite={handleChangeFavourite}
              />
            </div>
          ))}
      </DealsListing>

      <div className="notes">
        <Notes notes={notesFavourties} />
      </div>
    </div>
  );
};

export default PolicyDeals;
