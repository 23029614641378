import React from "react";
import QuoteDynamicHeader from "../../../../../Components/QuoteDynamicHeader/QuoteDynamicHeader";

const CaseClosed = () => {
  return (
    <div>
      <QuoteDynamicHeader
        title="Case Closed!"
        subTitle="Case Closed! Please Request New Quote"
      />
    </div>
  );
};

export default CaseClosed;
