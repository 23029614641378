// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_MAPPING_REQUEST_LIST,
  GET_MAPPING_DOC_LIST,
} from "../../../axios/apiendPoint";

const initialState = {
  isLoading: false,
  mappingRequestListingData: {},
  mappingList: [],
  pagination: {
    curPage: 1,
    totalPage: 0,
  },
  mappingBucket: {
    curBucket: "",
    bucketList: [],
  },
  // Upload docs list
  mappingRequestUploadDocumentList: null,
  currentMappingRequestData: null,
  // Modal States
  showMappingRequestDocModal: false,
};

// Fetch fetchMappingRequestData LISTING data
export const fetchMappingRequestData = createAsyncThunk(
  "mappingRequest/fetchMappingRequestData",
  async (thunkparam) => {
    const { axiosGet, searchParams } = thunkparam;
    const response = await axiosGet(GET_MAPPING_REQUEST_LIST, searchParams);
    return {
      // data: response?.data,
      mappingBucket: response?.data?.mappingBucket,
      mappingList: response?.data?.mappingList,
      pagination: response?.data?.pagination,
    };
  },
);
// Fetch fetchMappingRequestUploadDocumentsData listing data
export const fetchMappingRequestUploadDocumentsData = createAsyncThunk(
  "mappingRequest/fetchMappingRequestUploadDocumentsData",
  async (thunkparam) => {
    const { axiosGet, params } = thunkparam;
    const response = await axiosGet(GET_MAPPING_DOC_LIST, params);
    return { data: response?.data };
  },
);

export const mappRequestSlice = createSlice({
  name: "mappingRequest",
  initialState,
  reducers: {
    toggleMappingRequestDocModal: (state) => {
      state.showMappingRequestDocModal = !state.showMappingRequestDocModal;
    },
    setCurrentMappingRequestData: (state, action) => {
      state.currentMappingRequestData = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      // fetchQuoteRequestData
      .addCase(fetchMappingRequestData.fulfilled, (state, action) => {
        // state.mappingRequestListingData = action?.payload?.data;

        state.pagination = action?.payload.pagination;
        state.mappingBucket = action?.payload.mappingBucket;
        state.mappingList = action?.payload.mappingList;
        state.isLoading = false;
      })
      .addCase(fetchMappingRequestData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMappingRequestData.rejected, (state) => {
        state.isLoading = false;
      })

      // fetchMappingRequestUploadDocumentsData
      .addCase(
        fetchMappingRequestUploadDocumentsData.fulfilled,
        (state, action) => {
          state.mappingRequestUploadDocumentList = action?.payload?.data;
          state.isLoading = false;
        },
      )
      .addCase(fetchMappingRequestUploadDocumentsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMappingRequestUploadDocumentsData.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { toggleMappingRequestDocModal, setCurrentMappingRequestData } =
  mappRequestSlice.actions;

export default mappRequestSlice.reducer;
