import React, { Fragment } from "react";
import "./index.scss";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { X } from "react-feather";

const CustomModal = (props) => {
  const {
    isOpen,
    toggle,
    modaltitle,
    headerrow,
    modalbody,
    modalsize,
    width,
    height,
    maxWidth,
    noheadertoggle,
    nopadding,
    noModalPadding,
    background,
  } = props || {};

  const customStyles = {};

  if (width) {
    customStyles["--bs-modal-width"] = width;
  }
  if (height) {
    customStyles["--bs-modal-height"] = height;
  }

  if (maxWidth) {
    customStyles["maxWidth"] = maxWidth;
  }
  if (background) {
    customStyles["--bs-modal-bg"] = background;
  }

  return (
    <Modal
      isOpen={isOpen}
      size={modalsize || "lg"}
      toggle={toggle}
      {...props}
      centered
      className={`modal_container ${noModalPadding && "no_modal_padding"}`}
      // --bs-modal-width update this variable using style tag only if width is passed as props otherwise don't update
      style={customStyles}
      backdropClassName="modal_backdrop_fade"
    >
      {noheadertoggle && (
        <Fragment>
          <div className="d-flex align-items-center justify-content-between">
            {headerrow}

            <div className="extra_toggle" onClick={toggle}>
              <X size={20} />
            </div>
          </div>

          <hr className="header_bottom m-0 p-0 mt-1" />
        </Fragment>
      )}
      <ModalHeader
        toggle={toggle}
        className="modal_header "
        style={{ display: !modaltitle && "none" }}
      >
        {modaltitle}
      </ModalHeader>
      <ModalBody className={nopadding ? "modal_body p-0" : "modal_body"}>
        <div className="form_content">{modalbody}</div>
      </ModalBody>
    </Modal>
  );
};

export default CustomModal;
