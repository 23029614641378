import { useEffect } from "react";
import "./App.css";
import { Toaster } from "react-hot-toast";
import Router from "./Router";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "./axios/services";
import { fetchdashboardModalStates } from "./Pages/Dashboard/ModalSection/modalSectionStore/store";
import { createPortal } from "react-dom";

function App() {
  const dispatch = useDispatch();
  const { axiosGet } = useAxiosPrivate();
  const isPartnerRegistered = useSelector(
    (state) => state.dashboardModalReducer.isPartnerRegistered,
  );

  // Functions
  // Fetch modal state list
  const fetchModalList = () =>
    dispatch(fetchdashboardModalStates({ axiosGet }));

  // UseEffect
  useEffect(() => {
    fetchModalList();
  }, []);

  return (
    <div className="App">
      {/* Import Router component for routing between all pages */}
      <Router isPartnerRegistered={isPartnerRegistered} />

      {/* Create a portal to open toaster in body */}

      {createPortal(
        <Toaster
          toastOptions={{
            style: {
              zIndex: "2000 !important",
            },
          }}
        />,
        document.body,
      )}
    </div>
  );
}

export default App;
